* {
  font-family: "Inter", sans-serif;
  color: white
}

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Inter:wght@100..900&family=Tilt+Warp&display=swap');

#copy-notification.show {
  opacity: 1;
}

.ApprovedBtn:hover {
  background-color: #2f7add;
}

.ApprovedBtn:active {
  background-color: #2460ad;
}

.Approved {
  color: #4aaac2;
}

.formMain2-2 {
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000000000;
  background-color: rgba(0, 0, 0, 0.726);
}

.formMain-2-2 {
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: fit-content;
  height: fit-content;
  top: 50%;
  left: 50%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  z-index: 1;
  overflow: scroll;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  background-color: rgba(0, 0, 0, 0);
}

.formMain2 {
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000000000;
  background-color: rgba(0, 0, 0, 0.726);
}


@media screen and (max-width: 499px) {
  .wallet {
    display: flex;
    background-color: #2c3556;
    padding: 10px;
    border-radius: 10px;
  }

  .icon2 {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .amountDetails2 {
    background-color: #2c3556;
    padding: 20px;
    border-radius: 10px;
  }

  .smallH {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .filAmount {
    font-size: 16px;
    font-weight: normal;
  }

  .amountDetails {
    background-color: #2c3556;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
  margin-bottom: 20px;
  }

  .amountDetailsMain {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-flow: column nowrap;   
  }



  .balanceD {
    font-size: 18px;
    font-weight: bold;
  }

  .amountD {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
  }

  .iconsAddressMain {
    margin-top: 25px;
  }

  .iconsAddress {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .copyImg {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .copyImg:hover {
    opacity: 1;
  }

  .icon {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5%;
  }

  .iconsAddress div {
    color: #8e96ae;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    margin-top: 5px;
  }

  .flexBtns {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
  }

  .flexBtns div {
    margin: 10px;
  }

  .tfDiv {
    display: flex;
    justify-content: space-between;
  }

  .fil {
    color: #8e96ae7e;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .address2 {
    margin-left: 10px;
    margin-top: -10px;
  }

  .tfDiv img {
    width: 16px;
    height: 16px;
  }

  .ApprovedBtn {
    background-color: #266ac4;
    border: none;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    font-size: 14px;
  }

  #copy-notification {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: rgba(255, 255, 255, 0.281);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    font-size: 14px;
  }

  .copy {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
  }

  .copy:hover {
    opacity: 1;
  }

  .table-container {
    overflow-x: auto;
    max-width: 100%;
    margin: 20px auto;
  }

  #onClick {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  #selected2 {
    background-color: #3c435c8f;
    border: 1px solid #8e96ae9d;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .input-container-Main {
    background-color: #1f28469f;
    width: 90%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .input-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-flow: column nowrap;
  }

  .input-container div {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    font-size: 14px;
  }

  .inP1 {
    font-size: 25px;
    font-weight: bold;
  }

  .inP2 {
    color: #8e96ae;
    margin-top: -10px;
    margin-bottom: 30px;
    font-size: 15px;
  }

  .input-container input {
    background-color: #1f2949;
    border: none;
    outline: none;
    padding: 15px;
    border-radius: 15px;
    color: #8e96ae;
    font-size: 14px;
    margin-top: 10px;
  }

  li {
    color: #8e96ae;
  }

  li::marker {
    color: #98d5e9;
  }

  a {
    text-decoration: none;
    color: #8e96ae;
  }

  a:hover {
    color: #b7c1df;
  }

  .proMain {
    display: flex;
    justify-content: space-between;
  }

  table {
    border-collapse: collapse;
    /* Adjust width as needed */
    margin: 20px auto;
    /* Add some margin for better placement */
  }


  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #8e96ae3f;
    font-size: 14px;
    color: #b2bad3;
    cursor: pointer;
  }

  th {
    background-color: #8e96ae33;
    color: #c2cdee;
    font-weight: 500;
  }

  .selected {
    background-color: #c2cdee38;
  }

  .address {
    font-size: 12px;
    font-weight: 200;
    color: #bec8d3;
  }

  .action {
    text-align: center;
    /* Center text in the "ACTION" column */
  }

  .signerSection {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;

  }

  .signerSection div {
    font-size: 22px;
    color: #8e96ae;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .signerSection button {
    font-size: 16px;
    background: none;
    padding: 8px;
    border-radius: 50px;
    color: #8e96ae;
    border: 1px solid #8e96ae;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    width: 100%;
    margin-top: 10px;
    justify-content: flex-start;
  }

  .signerSection button:hover {
    background-color: #8e96ae25;
  }

  .logoDiv img:hover {
    cursor: pointer;
  }

  .footer-nav li:hover {
    color: #66ccff;
    cursor: pointer;
  }

  .multisigMain-1 {
    background-color: rgba(7, 19, 27, 0.842);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  #onClick {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
  }

  #selected2 {
    background-color: #3c435c8f;
    border: 1px solid #8e96ae9d;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
  }

  .input-container-Main {
    background-color: #1f28469f;
    width: 95%;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .input-container div {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    font-size: 14px;
  }

  .inP1 {
    font-size: 25px;
    font-weight: bold;
  }

  .inP2 {
    color: #8e96ae;
    margin-top: -10px;
    margin-bottom: 30px;
    font-size: 15px;
  }

  .input-container input {
    background-color: #1f2949;
    border: none;
    outline: none;
    width: 300px;
    padding: 15px;
    border-radius: 15px;
    color: #8e96ae;
    font-size: 14px;
  }

  .filAndAmount {
    background-color: #1f2949;
    width: 300px;
    border-radius: 15px;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 15px;
  }

  .filB {
    margin-right: 15px;
    padding-top: 15px;

  }

  li {
    color: #8e96ae;
  }

  li::marker {
    color: #98d5e9;
  }

  a {
    text-decoration: none;
    color: #8e96ae;
  }

  a:hover {
    color: #b7c1df;
  }

  .proMain {
    display: flex;
    justify-content: space-between;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    /* Adjust width as needed */
    margin: 20px auto;
    /* Add some margin for better placement */
  }


  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #8e96ae3f;
    font-size: 14px;
    color: #b2bad3;
    cursor: pointer;
  }

  th {
    background-color: #8e96ae33;
    color: #c2cdee;
    font-weight: 500;
  }

  .selected {
    background-color: #c2cdee38;
  }

  .address {
    font-size: 11px;
    font-weight: 200;
    color: #bec8d3;
    margin-top: 10px;
  }

  .action {
    text-align: center;
    /* Center text in the "ACTION" column */
  }

  .signerSection {
    display: flex;
    justify-content: space-between;
  }

  .signerSection div {
    font-size: 22px;
    color: #8e96ae;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .signerSection button {
    font-size: 16px;
    background: none;
    padding: 15px;
    border-radius: 50px;
    color: #8e96ae;
    border: 1px solid #8e96ae;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .signerSection button:hover {
    background-color: #8e96ae25;
  }

  .logoDiv img:hover {
    cursor: pointer;
  }

  .footer-nav li:hover {
    color: #66ccff;
    cursor: pointer;
  }

  .multisigMain-1 {
    background-color: rgba(7, 19, 27, 0.842);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    width: 90%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .bone {
    width: 22%;
    bottom: 4%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 2%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
  }

  .paw {
    width: 15%;
    bottom: 5%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 80%;
    rotate: 30deg;
  }

  .footer {
    background-color: #222;
    color: #fff;
    text-align: center;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.6634825805322129) 100%), url('./assets/footer.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top: 5px solid rgba(113, 179, 255, 0.185);
    padding: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    max-width: 90%;
    margin: auto;

  }

  .footer-logo {
    font-size: 24px;
    font-weight: bold;
    margin-right: auto;
    text-align: center;
    margin: auto;
    margin-top: 20px;
  }

  .footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .footer-nav li {
    display: inline-block;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 14px;
  }

  .footer-nav li:last-child {
    margin-right: 0;
  }

  .footer-nav li {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer-nav li a:hover {
    color: #66ccff;
  }

  .footer-text {
    font-size: 16px;
    font-family: "Inconsolata", monospace;
    color: #8e96ae;
    margin-bottom: 20px;
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #2ce7ba;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
    color: #2ce7ba;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #41e0fc;
    text-align: center;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }


  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  /* HTML: <div class="loader"></div> */
  .loader {
    height: 30px;
    aspect-ratio: 3;
    --c: no-repeat linear-gradient(#b141fc 0 0);
    background:
      var(--c) calc(0*100%/5) 50%,
      var(--c) calc(1*100%/5) 50%,
      var(--c) calc(2*100%/5) 50%,
      var(--c) calc(3*100%/5) 50%,
      var(--c) calc(4*100%/5) 50%,
      var(--c) calc(5*100%/5) 50%;
    background-size: calc(100%/6 + 1px) 50%;
    animation: l19 1s infinite cubic-bezier(0.5, 170, 0.5, -170);

  }

  @keyframes l19 {
    14.28% {
      background-position: calc(0*100%/5) 51%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    28.57% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 51%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    42.85% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 51%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    57.14% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 51%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    71.42% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 51%, calc(5*100%/5) 50%
    }

    85.71% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 51%
    }
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 12px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #535353;
  }

  .max {
    background-color: #9696965e;
    border: none;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 12px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.151) solid;
  }

  .max:hover {
    background-color: rgb(110, 110, 110);
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.20689994747899154) 0%, rgba(0, 0, 0, 0.4057794992997199) 50%, rgba(0, 0, 0, 0.8735666141456583) 100%), url('./assets/t10.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    filter: brightness(1);
  }


  .light2 {
    height: 100vh;
  }


  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

  }

  .miniBar {
    display: flex;
    justify-content: space-between;
    background-color: #3c79b300;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 18px;
    margin-left: 30px;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
    font-family: 'DM Sans';
    color: white;
    border-bottom: 1px solid rgba(48, 48, 48, 0.493);
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #ffffff50 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0a4796 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #02071a00;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    color: #8d98b0;
    font-family: "Inter", sans-serif;
  }

  #fontSize:hover {
    color: rgb(209, 209, 209);
  }

  .activeNav {
    color: #cad9fa;
  }

  .logoDiv {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv img {
    width: 80px;
    margin-right: 10px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 15px;
    margin-right: 15px;
  }

  .connectBtn {
    color: #daccff;
    padding: 11px;
    font-size: 16px;
    width: 175px;
    background-color: #7722ff69;
    border: none;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 15px;
    font-weight: 500;
    border: 1px solid #7722fffd;

  }

  .connectBtn:hover {
    background-color: #6226ff;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 60px;
    text-align: center;
  }

  .in1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 16px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    background: linear-gradient(to left, #2dd7b9, #2dd7b9, #3ab4cc, #3a0cb8, #2e0699);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
  }

  .in2 {
    font-size: 25px;
    color: rgb(255, 255, 255);
    margin-top: 15px;
    margin-bottom: 25px;
    font-family: "Tilt Warp", sans-serif;
  }

  .amount {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .number {
    color: #2ce7ba;
    font-size: 17px;
    font-weight: bold;
  }

  .nameNum {
    color: #8e96ae;
    font-weight: 600;
    font-size: 13px;
    margin-top: 8px;
  }

  .connectBtn2 {
    box-shadow: 0px 10px 45px rgba(5, 171, 221, 0.096);
    font-style: normal;
    font-size: 16px;
    margin-top: 40px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: #daccff;
    font-weight: bold;
    padding: 15px;
    font-size: 18px;
    width: 250px;
    background-color: #3713921f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(150, 222, 255);
    font-family: "Inconsolata", monospace;
    border-radius: 10px;
    /* Add this line for border-radius */
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.288);
    /* Add this line for glow effect on hover */

  }

  .connectBtn2:hover {
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.377);
    /* Add this line for glow effect on hover */
    color: #cbb6fc;
  }

  .shib {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 15%;
  }

  .faqI {
    width: 12%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 80%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
    transition: transform .2s;
    display: none;
  }

  .faqI:hover {
    transform: scale(1.1);
  }

  @keyframes floatAnimation {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5178243172268908) 0%, rgba(0, 0, 0, 0.7811296393557423) 50%, rgba(0, 0, 0, 0.8959755777310925) 100%), url('./assets/brick.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .header {
    color: white;
    text-align: center;
    font-size: 40px;
    margin-bottom: 2%;
    font-family: "Tilt Warp", sans-serif;
  }

  .aboutT {
    font-size: 40px;
    color: white;
    font-family: "Tilt Warp", sans-serif;
  }

  .icons {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons img {
    width: 20%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons img:hover {
    opacity: 1;
  }

  .footerMain {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(22, 22, 22);
  }

  .footer1 {
    color: rgb(88, 88, 88);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-top: 5px;
  }

  .iconsF {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .iconsF img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.5s ease;
    cursor: pointer;
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.932);
    border: 1px rgba(255, 255, 255, 0.322) solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    text-shadow: 0 0 10px #ffffff94;
    padding-bottom: 10px;
    text-align: left;
    font-size: 18px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  #faq {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .faqbg {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(17, 15, 35);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  /* STAKING CSS */

  .stake {
    min-height: 100vh;
    height: max-content;
  }

  .stakeMain {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    margin-top: 40px;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .stakeMain img {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .box1 {
    background-color: #00151dad;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 0 20px 0 rgba(42, 253, 243, 0.5);
    border: 1px solid rgba(42, 253, 242, 0.842);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .stakeH {
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }

  .stakeP {
    color: #8a90aa;
    font-weight: bold;
    line-height: 1.5;
    font-family: "Inconsolata", monospace;
    font-size: 20px;
  }

  #balance2 {
    color: #8a90aa;
    font-weight: 500;
  }

  .stakeH2 {
    color: white;
    line-height: 1.5;
    font-size: 14px;
  }

  .stakeBox {
    background-color: rgba(7, 19, 27, 0.842);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .inputSection input {
    font-size: 16px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }


  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .inputSection input {
    font-size: 16px;
    padding: 13px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .note {
    margin-bottom: 35px;
    color: #6b6b6b;
  }

  .hl2 {
    color: #246474;
  }

  .rewards {
    text-align: center;
    margin-bottom: 10%;
    margin-top: 10%;
    font-size: 22px;
    color: #2ce7ba;
  }

  .inputError {
    color: rgb(255, 79, 79);
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }


  .inputSection2 {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
  }

  .inputSection2 input {
    font-size: 16px;
    padding: 13px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection2 div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
    font-size: 15px;
  }

  .stakeBox2 {
    margin-top: 5%;
  }

  .deposit {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .deposit:hover {
    background-color: #8b44ac;
  }

  .depositMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .stakeBox2Flex {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .stakeNav {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    margin-top: 20px;
  }

  .stakeNav div {
    background-color: #003d6656;
    padding: 10px;
    border-radius: 10px;
    width: 85px;
    text-align: center;
    cursor: pointer;
    transition: 0.5 ease-in-out;
    font-size: 13px;
  }

  .stakeNav div:hover {
    background-color: #0064a756;
  }

  #onStake {
    background-color: #0585db9a;
    border: 1px solid #8a90aa;
  }

  .balance {
    font-size: 15px;
    font-weight: bold;
    font-family: "Inconsolata", monospace;
    display: flex;
    justify-content: space-between;
  }

  #balance {
    font-size: 18px;
    color: #4ddbff;
  }

  .line {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    margin-top: 15px;
    background-color: rgba(159, 195, 248, 0.288);
  }

  .multisigMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    padding-top: 20px;
  }


  .section1M {
    padding: 15px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .section2M {
    padding: 15px;
    width: 100%;
  }

  .m1 {
    font-size: 18px;
    color: white;
    margin-bottom: 20px;
  }

  .m2 {
    font-size: 18px;
    color: #9198b0;
    margin-bottom: 20px;
  }

  .lines2Main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

  }

  .lines2 {
    height: 1px;
    width: 70%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2-2 {
    height: 1px;
    width: 100%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2Con {
    color: #9198b0;
    width: 100%;
    text-align: center;
  }

  .lines2Con-2 {
    color: #9198b0;
    text-align: center;
    padding: 10px;
  }

  .inpuyDiv2 {
    border: #c3cdee 2px solid;
    border-radius: 7px;
    margin-bottom: 7%;
  }

  .inpuyDiv2 input {
    border: none;
    outline: none;
    background: none;
    padding: 10px;
    font-size: 16x;
  }

  .buttonM {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buttonM:hover {
    background-color: #8b44ac;
  }

  .multisig2ndBox {
    display: flex;
    justify-content: space-between;
  }

  .tokensT {
    font-size: 18px;
    font-weight: bold;
    color: #9198b0;
    margin-top: 25px;
  }

  .box2M {
    background-color: #1f2846;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 5%;
  }

  .multisigH {
    color: #818aa3;
    font-weight: bold;
    font-size: 25px;
  }

  .multisigH2 {
    color: #818aa3;
    margin-bottom: 25px;
  }


}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .wallet {
    display: flex;
    background-color: #2c3556;
    padding: 10px;
    border-radius: 10px;
  }

  .icon2 {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .amountDetails2 {
    background-color: #2c3556;
    padding: 20px;
    border-radius: 10px;
  }

  .smallH {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .smallBtns {
    width: 90%;
  }

  .filAmount {
    font-size: 16px;
    font-weight: normal;
  }

  .amountDetails {
    background-color: #2c3556;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
  margin-bottom: 20px;
  }

  .amountDetailsMain {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-flow: column nowrap;   
  }



  .balanceD {
    font-size: 18px;
    font-weight: bold;
  }

  .amountD {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
  }

  .iconsAddressMain {
    margin-top: 25px;
  }

  .iconsAddress {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .copyImg {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .copyImg:hover {
    opacity: 1;
  }

  .icon {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5%;
  }

  .iconsAddress div {
    color: #8e96ae;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    margin-top: 5px;
  }

  .flexBtns {
    display: flex;
    justify-content: space-between;
  }

  .flexBtns div {
    margin: 10px;
  }

  .tfDiv {
    display: flex;
    justify-content: space-between;
  }

  .fil {
    color: #8e96ae7e;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .address2 {
    margin-top: 20px;
    margin-left: 15px;
  }

  .tfDiv img {
    width: 20px;
    height: 20px;
  }

  .ApprovedBtn {
    background-color: #266ac4;
    border: none;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    font-size: 14px;
  }

  .approvediv {
    flex-flow: column nowrap;
  }

  #copy-notification {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: rgba(255, 255, 255, 0.281);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .copy {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
  }

  .copy:hover {
    opacity: 1;
  }

  #onClick {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  #selected2 {
    background-color: #3c435c8f;
    border: 1px solid #8e96ae9d;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .input-container-Main {
    background-color: #1f28469f;
    width: 90%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .input-container div {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    font-size: 14px;
  }

  .inP1 {
    font-size: 25px;
    font-weight: bold;
  }

  .inP2 {
    color: #8e96ae;
    margin-top: -10px;
    margin-bottom: 30px;
    font-size: 15px;
  }

  .input-container input {
    background-color: #1f2949;
    border: none;
    outline: none;
    width: 300px;
    padding: 15px;
    border-radius: 15px;
    color: #8e96ae;
    font-size: 14px;
  }

  .filAndAmount {
    background-color: #1f2949;
    width: 300px;
    border-radius: 15px;
  }

  .filB {
    margin-right: 15px;
  }

  li {
    color: #8e96ae;
  }

  li::marker {
    color: #98d5e9;
  }

  a {
    text-decoration: none;
    color: #8e96ae;
  }

  a:hover {
    color: #b7c1df;
  }

  .proMain {
    display: flex;
    justify-content: space-between;
  }

  .table-container {
    overflow-x: auto;
    max-width: 100%;
    margin: 20px auto;
  }

  table {
    border-collapse: collapse;
    /* Adjust width as needed */
    margin: 20px auto;
    /* Add some margin for better placement */
  }

  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #8e96ae3f;
    font-size: 14px;
    color: #b2bad3;
    cursor: pointer;
  }

  th {
    background-color: #8e96ae33;
    color: #c2cdee;
    font-weight: 500;
  }

  .selected {
    background-color: #c2cdee38;
  }

  .address {
    font-size: 12px;
    font-weight: 200;
    color: #bec8d3;
    margin-top: 10px;
  }

  .action {
    text-align: center;
    /* Center text in the "ACTION" column */
  }

  .signerSection {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;

  }

  .signerSection div {
    font-size: 22px;
    color: #8e96ae;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .signerSection button {
    font-size: 16px;
    background: none;
    padding: 12px;
    border-radius: 50px;
    color: #8e96ae;
    border: 1px solid #8e96ae;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    width: fit-content;
    margin-top: 10px;
  }

  .signerSection button:hover {
    background-color: #8e96ae25;
  }

  .logoDiv img:hover {
    cursor: pointer;
  }

  .footer-nav li:hover {
    color: #66ccff;
    cursor: pointer;
  }

  .multisigMain-1 {
    background-color: rgba(7, 19, 27, 0.842);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    width: 90%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .bone {
    width: 22%;
    bottom: 4%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 2%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
  }

  .paw {
    width: 15%;
    bottom: 5%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 80%;
    rotate: 30deg;
  }

  .footer {
    background-color: #222;
    color: #fff;
    text-align: center;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.6634825805322129) 100%), url('./assets/footer.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top: 5px solid rgba(113, 179, 255, 0.185);
    padding: 50px;
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    max-width: 90%;
    margin: auto;

  }

  .footer-logo {
    font-size: 24px;
    font-weight: bold;
    margin-right: auto;
    text-align: center;
    margin: auto;
  }

  .footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .footer-nav li {
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;

  }

  .footer-nav li:last-child {
    margin-right: 0;
  }

  .footer-nav li {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer-nav li a:hover {
    color: #66ccff;
  }

  .footer-text {
    font-size: 16px;
    font-family: "Inconsolata", monospace;
    color: #8e96ae;
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #2ce7ba;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
    color: #2ce7ba;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #41e0fc;
    text-align: center;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }


  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  /* HTML: <div class="loader"></div> */
  .loader {
    height: 30px;
    aspect-ratio: 3;
    --c: no-repeat linear-gradient(#b141fc 0 0);
    background:
      var(--c) calc(0*100%/5) 50%,
      var(--c) calc(1*100%/5) 50%,
      var(--c) calc(2*100%/5) 50%,
      var(--c) calc(3*100%/5) 50%,
      var(--c) calc(4*100%/5) 50%,
      var(--c) calc(5*100%/5) 50%;
    background-size: calc(100%/6 + 1px) 50%;
    animation: l19 1s infinite cubic-bezier(0.5, 170, 0.5, -170);
  }

  @keyframes l19 {
    14.28% {
      background-position: calc(0*100%/5) 51%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    28.57% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 51%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    42.85% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 51%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    57.14% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 51%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    71.42% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 51%, calc(5*100%/5) 50%
    }

    85.71% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 51%
    }
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 12px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #535353;
  }

  .max {
    background-color: #9696965e;
    border: none;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 15px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.151) solid;
  }

  .max:hover {
    background-color: rgb(110, 110, 110);
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5178243172268908) 0%, rgba(0, 0, 0, 0.7811296393557423) 50%, rgba(0, 0, 0, 0.8959755777310925) 100%), url('./assets/t10.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    filter: brightness(1);
  }


  .light2 {
    height: 100vh;
  }


  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

  }

  .miniBar {
    display: flex;
    justify-content: space-between;
    background-color: #3c79b300;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 18px;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
    font-family: 'DM Sans';
    color: white;
    border-bottom: 1px solid rgba(48, 48, 48, 0.493);
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #ffffff50 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0a4796 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #02071a00;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    color: #8d98b0;
    font-family: "Inter", sans-serif;
  }

  #fontSize:hover {
    color: rgb(209, 209, 209);
  }

  .activeNav {
    color: #cad9fa;
  }

  .logoDiv {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv img {
    width: 80px;
    margin-right: 10px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 15px;
    margin-right: 15px;
  }

  .connectBtn {
    color: #daccff;
    padding: 11px;
    font-size: 16px;
    width: 175px;
    background-color: #7722ff69;
    border: none;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 15px;
    font-weight: 500;
    border: 1px solid #7722fffd;

  }

  .connectBtn:hover {
    background-color: #6226ff;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 60px;
    text-align: center;
  }

  .in1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    background: linear-gradient(to left, #2dd7b9, #2dd7b9, #3ab4cc, #3a0cb8, #2e0699);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
  }

  .in2 {
    font-size: 35px;
    color: rgb(255, 255, 255);
    margin-top: 15px;
    margin-bottom: 25px;
    font-family: "Tilt Warp", sans-serif;
  }

  .amount {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .number {
    color: #2ce7ba;
    font-size: 20px;
    font-weight: bold;
  }

  .nameNum {
    color: #8e96ae;
    font-weight: 600;
    font-size: 14px;
    margin-top: 8px;
  }

  .connectBtn2 {
    box-shadow: 0px 10px 45px rgba(5, 171, 221, 0.096);
    font-style: normal;
    font-size: 16px;
    margin-top: 5%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: #daccff;
    font-weight: bold;
    padding: 18px;
    font-size: 20px;
    width: 300px;
    background-color: #3713921f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(150, 222, 255);
    font-family: "Inconsolata", monospace;
    border-radius: 10px;
    /* Add this line for border-radius */
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.288);
    /* Add this line for glow effect on hover */

  }

  .connectBtn2:hover {
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.377);
    /* Add this line for glow effect on hover */
    color: #cbb6fc;
  }

  .shib {
    width: 32%;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
  }

  .faqI {
    width: 10%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 85%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
    transition: transform .2s;
  }

  .faqI:hover {
    transform: scale(1.1);
  }

  @keyframes floatAnimation {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5178243172268908) 0%, rgba(0, 0, 0, 0.7811296393557423) 50%, rgba(0, 0, 0, 0.8959755777310925) 100%), url('./assets/brick.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .header {
    color: white;
    text-align: center;
    font-size: 40px;
    margin-bottom: 2%;
    font-family: "Tilt Warp", sans-serif;
  }

  .aboutT {
    font-size: 40px;
    color: white;
    font-family: "Tilt Warp", sans-serif;
  }

  .icons {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons img {
    width: 20%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons img:hover {
    opacity: 1;
  }

  .footerMain {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(22, 22, 22);
  }

  .footer1 {
    color: rgb(88, 88, 88);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-top: 5px;
  }

  .iconsF {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .iconsF img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.5s ease;
    cursor: pointer;
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.932);
    border: 1px rgba(255, 255, 255, 0.322) solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    text-shadow: 0 0 10px #ffffff94;
    padding-bottom: 10px;
    text-align: left;
    font-size: 18px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  #faq {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .faqbg {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(17, 15, 35);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  /* STAKING CSS */

  .stake {
    min-height: 100vh;
    height: max-content;
  }

  .stakeMain {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    margin-top: 40px;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .stakeMain img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .box1 {
    background-color: #00151dad;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 0 20px 0 rgba(42, 253, 243, 0.5);
    border: 1px solid rgba(42, 253, 242, 0.842);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .stakeH {
    color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
  }

  .stakeP {
    color: #8a90aa;
    font-weight: bold;
    line-height: 1.5;
    font-family: "Inconsolata", monospace;
    font-size: 20px;
  }

  #balance2 {
    color: #8a90aa;
    font-weight: 500;
  }

  .stakeH2 {
    color: white;
    line-height: 1.5;
  }

  .stakeBox {
    background-color: rgba(7, 19, 27, 0.842);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .inputSection input {
    font-size: 16px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }


  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .inputSection input {
    font-size: 16px;
    padding: 13px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .note {
    margin-bottom: 35px;
    color: #6b6b6b;
  }

  .hl2 {
    color: #246474;
  }

  .rewards {
    text-align: center;
    margin-bottom: 10%;
    margin-top: 10%;
    font-size: 25px;
    color: #2ce7ba;
  }

  .inputError {
    color: rgb(255, 79, 79);
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }


  .inputSection2 {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
  }

  .inputSection2 input {
    font-size: 16px;
    padding: 13px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection2 div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }

  .stakeBox2 {
    margin-top: 5%;
  }

  .deposit {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .deposit:hover {
    background-color: #8b44ac;
  }

  .depositMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .stakeBox2Flex {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .stakeNav {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    margin-top: 20px;
  }

  .stakeNav div {
    background-color: #003d6656;
    padding: 10px;
    border-radius: 10px;
    width: 110px;
    text-align: center;
    cursor: pointer;
    transition: 0.5 ease-in-out;
    font-size: 15px;
  }

  .stakeNav div:hover {
    background-color: #0064a756;
  }

  #onStake {
    background-color: #0585db9a;
    border: 1px solid #8a90aa;
  }

  .balance {
    font-size: 15px;
    font-weight: bold;
    font-family: "Inconsolata", monospace;
    display: flex;
    justify-content: space-between;
  }

  #balance {
    font-size: 18px;
    color: #4ddbff;
  }

  .line {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    margin-top: 15px;
    background-color: rgba(159, 195, 248, 0.288);
  }

  .multisigMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
  }


  .section1M {
    padding: 30px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .section2M {
    padding: 30px;
    width: 100%;
  }

  .m1 {
    font-size: 18px;
    color: white;
    margin-bottom: 25px;
  }

  .m2 {
    font-size: 18px;
    color: #9198b0;
    margin-bottom: 25px;
  }

  .lines2Main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

  }

  .lines2 {
    height: 1px;
    width: 100%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2-2 {
    height: 1px;
    width: 100%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2Con {
    color: #9198b0;
    width: 100%;
    text-align: center;
  }

  .lines2Con-2 {
    color: #9198b0;
    text-align: center;
    padding: 10px;
  }

  .inpuyDiv2 {
    border: #c3cdee 2px solid;
    border-radius: 7px;
    margin-bottom: 7%;
  }

  .inpuyDiv2 input {
    border: none;
    outline: none;
    background: none;
    padding: 10px;
    font-size: 18px;
  }

  .buttonM {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buttonM:hover {
    background-color: #8b44ac;
  }

  .multisig2ndBox {
    display: flex;
    justify-content: space-between;
  }

  .tokensT {
    font-size: 18px;
    font-weight: bold;
    color: #9198b0;
    margin-top: 25px;
  }

  .box2M {
    background-color: #1f2846;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 5%;
  }

  .multisigH {
    color: #818aa3;
    font-weight: bold;
    font-size: 30px;
  }

  .multisigH2 {
    color: #818aa3;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .wallet {
    display: flex;
    background-color: #2c3556;
    padding: 10px;
    border-radius: 10px;
  }

  .icon2 {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .amountDetails2 {
    background-color: #2c3556;
    padding: 20px;
    border-radius: 10px;
  }

  .smallH {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .smallBtns {
    width: 120px;
  }

  .filAmount {
    font-size: 16px;
    font-weight: normal;
  }

  .amountDetails {
    background-color: #2c3556;
    padding: 20px;
    width: 250px;
    border-radius: 10px;
  }

  .amountDetailsMain {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }



  .balanceD {
    font-size: 18px;
    font-weight: bold;
  }

  .amountD {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
  }

  .iconsAddressMain {
    margin-top: 25px;
  }

  .iconsAddress {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .copyImg {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .copyImg:hover {
    opacity: 1;
  }

  .icon {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5%;
  }

  .iconsAddress div {
    color: #8e96ae;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    margin-top: 5px;
  }

  .flexBtns {
    display: flex;
    justify-content: space-between;
  }

  .flexBtns div {
    margin: 10px;
  }

  .fil {
    color: #8e96ae7e;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .tfDiv {
    display: flex;
    justify-content: space-between;
  }

  .address2 {
    margin-top: 20px;
    margin-left: 15px;
  }

  .tfDiv img {
    width: 20px;
    height: 20px;
  }

  .ApprovedBtn {
    background-color: #266ac4;
    border: none;
    border-radius: 5px;
    padding: 5px;
    margin-left: 7px;
  }

  #copy-notification {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: rgba(255, 255, 255, 0.281);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .copy {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
  }

  .copy:hover {
    opacity: 1;
  }

  #onClick {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  #selected2 {
    background-color: #3c435c8f;
    border: 1px solid #8e96ae9d;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .input-container-Main {
    background-color: #1f28469f;
    width: 90%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .input-container div {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
  }

  .inP1 {
    font-size: 30px;
    font-weight: bold;
  }

  .inP2 {
    color: #8e96ae;
    margin-top: -10px;
    margin-bottom: 30px;
  }

  .input-container input {
    background-color: #1f2949;
    border: none;
    outline: none;
    width: 400px;
    padding: 15px;
    border-radius: 15px;
    color: #8e96ae;

  }


  .filAndAmount {
    background-color: #1f2949;
    width: 400px;
    border-radius: 15px;
  }

  .filB {
    margin-right: 15px;
  }

  li {
    color: #8e96ae;
  }

  li::marker {
    color: #98d5e9;
  }

  a {
    text-decoration: none;
    color: #8e96ae;
  }

  a:hover {
    color: #b7c1df;
  }

  .proMain {
    display: flex;
    justify-content: space-between;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    /* Adjust width as needed */
    margin: 20px auto;
    /* Add some margin for better placement */
  }


  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #8e96ae3f;
    font-size: 14px;
    color: #b2bad3;
    cursor: pointer;
  }

  th {
    background-color: #8e96ae33;
    color: #c2cdee;
    font-weight: 500;
  }

  .selected {
    background-color: #c2cdee38;
  }

  .address {
    font-size: 16px;
    font-weight: 200;
    color: #bec8d3;
    margin-top: 10px;
  }

  .action {
    text-align: center;
    /* Center text in the "ACTION" column */
  }

  .signerSection {
    display: flex;
    justify-content: space-between;
  }

  .signerSection div {
    font-size: 22px;
    color: #8e96ae;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .signerSection button {
    font-size: 16px;
    background: none;
    padding: 15px;
    border-radius: 50px;
    color: #8e96ae;
    border: 1px solid #8e96ae;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .signerSection button:hover {
    background-color: #8e96ae25;
  }

  .logoDiv img:hover {
    cursor: pointer;
  }

  .footer-nav li:hover {
    color: #66ccff;
    cursor: pointer;
  }

  .multisigMain-1 {
    background-color: rgba(7, 19, 27, 0.842);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }

  .bone {
    width: 22%;
    bottom: 4%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 2%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
  }


  .paw {
    width: 12%;
    bottom: 5%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 85%;
    rotate: 30deg;
  }

  .footer {
    background-color: #222;
    color: #fff;
    text-align: center;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.6634825805322129) 100%), url('./assets/footer.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top: 5px solid rgba(113, 179, 255, 0.185);
    padding: 30px;
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    max-width: 90%;
    margin: auto;

  }

  .footer-logo {
    font-size: 24px;
    font-weight: bold;
    margin-right: auto;
    text-align: center;
    margin: auto;
  }

  .footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .footer-nav li {
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;

  }

  .footer-nav li:last-child {
    margin-right: 0;
  }

  .footer-nav li {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer-nav li a:hover {
    color: #66ccff;
  }

  .footer-text {

    font-size: 16px;
    font-family: "Inconsolata", monospace;
    color: #8e96ae;

  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #2ce7ba;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
    color: #2ce7ba;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #41e0fc;
    text-align: center;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }


  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  /* HTML: <div class="loader"></div> */
  .loader {
    height: 30px;
    aspect-ratio: 3;
    --c: no-repeat linear-gradient(#b141fc 0 0);
    background:
      var(--c) calc(0*100%/5) 50%,
      var(--c) calc(1*100%/5) 50%,
      var(--c) calc(2*100%/5) 50%,
      var(--c) calc(3*100%/5) 50%,
      var(--c) calc(4*100%/5) 50%,
      var(--c) calc(5*100%/5) 50%;
    background-size: calc(100%/6 + 1px) 50%;
    animation: l19 1s infinite cubic-bezier(0.5, 170, 0.5, -170);
  }

  @keyframes l19 {
    14.28% {
      background-position: calc(0*100%/5) 51%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    28.57% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 51%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    42.85% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 51%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    57.14% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 51%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    71.42% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 51%, calc(5*100%/5) 50%
    }

    85.71% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 51%
    }
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 12px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #535353;
  }

  .max {
    background-color: #9696965e;
    border: none;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 15px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.151) solid;
  }

  .max:hover {
    background-color: rgb(110, 110, 110);
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.20689994747899154) 0%, rgba(0, 0, 0, 0.4057794992997199) 50%, rgba(0, 0, 0, 0.8735666141456583) 100%), url('./assets/t10.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    filter: brightness(1);
  }


  .light2 {
    height: 100vh;
  }


  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

  }

  .miniBar {
    display: flex;
    justify-content: space-between;
    background-color: #3c79b300;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
    font-family: 'DM Sans';
    color: white;
    border-bottom: 1px solid rgba(48, 48, 48, 0.493);
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #ffffff50 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0a4796 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #02071a00;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    color: #8d98b0;
    font-family: "Inter", sans-serif;
  }

  #fontSize:hover {
    color: rgb(209, 209, 209);
  }

  .activeNav {
    color: #cad9fa;
  }

  .logoDiv {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv img {
    width: 80px;
    margin-right: 10px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 15px;
    margin-right: 15px;
  }

  .connectBtn {
    color: #daccff;
    padding: 11px;
    font-size: 16px;
    width: 175px;
    background-color: #7722ff69;
    border: none;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 15px;
    font-weight: 500;
    border: 1px solid #7722fffd;

  }

  .connectBtn:hover {
    background-color: #6226ff;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 70px;
    text-align: center;
  }

  .in1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 23px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    background: linear-gradient(to left, #2dd7b9, #2dd7b9, #3ab4cc, #3a0cb8, #2e0699);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
  }

  .in2 {
    font-size: 40px;
    color: rgb(255, 255, 255);
    margin-top: 15px;
    margin-bottom: 25px;
    font-family: "Tilt Warp", sans-serif;
  }

  .amount {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .number {
    color: #2ce7ba;
    font-size: 23px;
    font-weight: bold;
  }

  .nameNum {
    color: #8e96ae;
    font-weight: 600;
    font-size: 14px;
    margin-top: 8px;
  }

  .connectBtn2 {
    box-shadow: 0px 10px 45px rgba(5, 171, 221, 0.096);
    font-style: normal;
    font-size: 16px;
    margin-top: 5%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: #daccff;
    font-weight: bold;
    padding: 18px;
    font-size: 20px;
    width: 300px;
    background-color: #3713921f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(150, 222, 255);
    font-family: "Inconsolata", monospace;
    border-radius: 10px;
    /* Add this line for border-radius */
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.288);
    /* Add this line for glow effect on hover */

  }

  .connectBtn2:hover {
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.377);
    /* Add this line for glow effect on hover */
    color: #cbb6fc;
  }

  .shib {
    width: 32%;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
  }

  .faqI {
    width: 7%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 88%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
    transition: transform .2s;
  }

  .faqI:hover {
    transform: scale(1.1);
  }

  @keyframes floatAnimation {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5178243172268908) 0%, rgba(0, 0, 0, 0.7811296393557423) 50%, rgba(0, 0, 0, 0.8959755777310925) 100%), url('./assets/brick.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .header {
    color: white;
    text-align: center;
    font-size: 40px;
    margin-bottom: 2%;
    font-family: "Tilt Warp", sans-serif;
  }

  .aboutT {
    font-size: 40px;
    color: white;
    font-family: "Tilt Warp", sans-serif;
  }

  .icons {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons img {
    width: 20%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons img:hover {
    opacity: 1;
  }

  .footerMain {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(22, 22, 22);
  }

  .footer1 {
    color: rgb(88, 88, 88);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-top: 5px;
  }

  .iconsF {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .iconsF img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.5s ease;
    cursor: pointer;
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.932);
    border: 1px rgba(255, 255, 255, 0.322) solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    text-shadow: 0 0 10px #ffffff94;
    padding-bottom: 10px;
    text-align: left;
    font-size: 18px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  #faq {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .faqbg {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(17, 15, 35);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  /* STAKING CSS */

  .stake {
    min-height: 100vh;
    height: max-content;
  }

  .stakeMain {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    margin-top: 40px;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .stakeMain img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .box1 {
    background-color: #00151dad;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 0 20px 0 rgba(42, 253, 243, 0.5);
    border: 1px solid rgba(42, 253, 242, 0.842);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .stakeH {
    color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
  }

  .stakeP {
    color: #8a90aa;
    font-weight: bold;
    line-height: 1.5;
    font-family: "Inconsolata", monospace;
    font-size: 20px;
  }

  #balance2 {
    color: #8a90aa;
    font-weight: 500;
  }

  .stakeH2 {
    color: white;
    line-height: 1.5;
  }

  .stakeBox {
    background-color: rgba(7, 19, 27, 0.842);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .inputSection input {
    font-size: 18px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }


  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .inputSection input {
    font-size: 18px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .note {
    margin-bottom: 35px;
    color: #6b6b6b;
  }

  .hl2 {
    color: #246474;
  }

  .rewards {
    text-align: center;
    margin-bottom: 10%;
    margin-top: 10%;
    font-size: 30px;
    color: #2ce7ba;
  }

  .inputError {
    color: rgb(255, 79, 79);
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }


  .inputSection2 {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
  }

  .inputSection2 input {
    font-size: 18px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection2 div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }

  .stakeBox2 {
    margin-top: 5%;
  }

  .deposit {
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .deposit:hover {
    background-color: #8b44ac;
  }

  .depositMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .stakeBox2Flex {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .stakeNav {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    margin-top: 20px;
  }

  .stakeNav div {
    background-color: #003d6656;
    padding: 10px;
    border-radius: 10px;
    width: 180px;
    text-align: center;
    cursor: pointer;
    transition: 0.5 ease-in-out;
  }

  .stakeNav div:hover {
    background-color: #0064a756;
  }

  #onStake {
    background-color: #0585db9a;
    border: 1px solid #8a90aa;
  }

  .balance {
    font-size: 16px;
    font-weight: bold;
    font-family: "Inconsolata", monospace;
    display: flex;
    justify-content: space-between;
  }

  #balance {
    font-size: 20px;
    color: #4ddbff;
  }

  .line {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    margin-top: 15px;
    background-color: rgba(159, 195, 248, 0.288);
  }

  .multisigMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
  }

  .multisigMain2 {
    position: sticky;
    top: 27%;
  }

  .section1M {
    padding: 30px;
    width: 100%;

  }

  .section2M {
    padding: 30px;
    width: 100%;
  }

  .m1 {
    font-size: 18px;
    color: white;
    margin-bottom: 25px;
  }

  .m2 {
    font-size: 18px;
    color: #9198b0;
    margin-bottom: 25px;
  }

  .lines2Main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

  }

  .lines2 {
    height: 1px;
    width: 50%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2-2 {
    height: 1px;
    width: 100%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2Con {
    color: #9198b0;
    width: 100%;
    text-align: center;
  }

  .lines2Con-2 {
    color: #9198b0;
    text-align: center;
    padding: 10px;
  }

  .inpuyDiv2 {
    border: #c3cdee 2px solid;
    border-radius: 7px;
    margin-bottom: 7%;
  }

  .inpuyDiv2 input {
    border: none;
    outline: none;
    background: none;
    padding: 10px;
    font-size: 18px;
  }

  .buttonM {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buttonM:hover {
    background-color: #8b44ac;
  }

  .multisig2ndBox {
    display: flex;
    justify-content: space-between;
  }

  .tokensT {
    font-size: 18px;
    font-weight: bold;
    color: #9198b0;
    margin-top: 25px;
  }

  .box2M {
    background-color: #1f2846;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 5%;
  }

  .multisigH {
    color: #818aa3;
    font-weight: bold;
    font-size: 30px;
  }

  .multisigH2 {
    color: #818aa3;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .wallet {
    display: flex;
    background-color: #2c3556;
    padding: 10px;
    border-radius: 10px;
  }

  .icon2 {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .amountDetails2 {
    background-color: #2c3556;
    padding: 20px;
    border-radius: 10px;
  }

  .smallH {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .smallBtns {
    width: 120px;
  }

  .filAmount {
    font-size: 16px;
    font-weight: normal;
  }

  .amountDetails {
    background-color: #2c3556;
    padding: 20px;
    width: 250px;
    border-radius: 10px;
  }

  .amountDetailsMain {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }



  .balanceD {
    font-size: 18px;
    font-weight: bold;
  }

  .amountD {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
  }

  .iconsAddressMain {
    margin-top: 25px;
  }

  .iconsAddress {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .copyImg {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .copyImg:hover {
    opacity: 1;
  }

  .icon {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 30%;
  }

  .iconsAddress div {
    color: #8e96ae;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    margin-top: 5px;
  }

  .flexBtns {
    display: flex;
    justify-content: space-between;
  }

  .flexBtns div {
    margin: 10px;
  }

  .fil {
    color: #8e96ae7e;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .tfDiv {
    display: flex;
    justify-content: space-between;
  }

  .address2 {
    margin-top: 20px;
    margin-left: 15px;
  }

  .tfDiv img {
    width: 20px;
    height: 20px;
  }

  .ApprovedBtn {
    background-color: #266ac4;
    border: none;
    border-radius: 5px;
    padding: 5px;
    margin-left: 7px;
  }

  #copy-notification {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: rgba(255, 255, 255, 0.281);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .copy {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
  }

  .copy:hover {
    opacity: 1;
  }

  #onClick {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  #selected2 {
    background-color: #3c435c8f;
    border: 1px solid #8e96ae9d;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .input-container-Main {
    background-color: #1f28469f;
    width: 90%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .input-container div {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
  }

  .inP1 {
    font-size: 30px;
    font-weight: bold;
  }

  .inP2 {
    color: #8e96ae;
    margin-top: -10px;
    margin-bottom: 30px;
  }

  .input-container input {
    background-color: #1f2949;
    border: none;
    outline: none;
    width: 500px;
    padding: 15px;
    border-radius: 15px;
    color: #8e96ae;

  }


  .filAndAmount {
    background-color: #1f2949;
    width: 500px;
    border-radius: 15px;
  }

  .filB {
    margin-right: 15px;
  }

  li {
    color: #8e96ae;
  }

  li::marker {
    color: #98d5e9;
  }

  a {
    text-decoration: none;
    color: #8e96ae;
  }

  a:hover {
    color: #b7c1df;
  }

  .proMain {
    display: flex;
    justify-content: space-between;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    /* Adjust width as needed */
    margin: 20px auto;
    /* Add some margin for better placement */
  }


  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #8e96ae3f;
    font-size: 14px;
    color: #b2bad3;
    cursor: pointer;
  }

  th {
    background-color: #8e96ae33;
    color: #c2cdee;
    font-weight: 500;
  }

  .selected {
    background-color: #c2cdee38;
  }

  .address {
    font-size: 16px;
    font-weight: 200;
    color: #bec8d3;
    margin-top: 10px;
  }

  .action {
    text-align: center;
    /* Center text in the "ACTION" column */
  }

  .signerSection {
    display: flex;
    justify-content: space-between;
  }

  .signerSection div {
    font-size: 22px;
    color: #8e96ae;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .signerSection button {
    font-size: 16px;
    background: none;
    padding: 15px;
    border-radius: 50px;
    color: #8e96ae;
    border: 1px solid #8e96ae;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .signerSection button:hover {
    background-color: #8e96ae25;
  }

  .logoDiv img:hover {
    cursor: pointer;
  }

  .footer-nav li:hover {
    color: #66ccff;
    cursor: pointer;
  }

  .multisigMain-1 {
    background-color: rgba(7, 19, 27, 0.842);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }

  .bone {
    width: 14%;
    bottom: 4%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 2%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
  }

  .paw {
    width: 7%;
    bottom: 5%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 90%;
    rotate: 30deg;
  }

  .footer {
    background-color: #222;
    color: #fff;
    padding: 50px 0;
    text-align: center;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.6634825805322129) 100%), url('./assets/footer.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top: 5px solid rgba(113, 179, 255, 0.185);
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
  }

  .footer-logo {
    font-size: 24px;
    font-weight: bold;
    margin-right: auto;
  }

  .footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: auto;
  }

  .footer-nav li {
    display: inline-block;
    margin-right: 20px;
  }

  .footer-nav li:last-child {
    margin-right: 0;
  }

  .footer-nav li {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }



  .footer-text {
    margin-top: 20px;
    font-size: 16px;
    font-family: "Inconsolata", monospace;
    color: #8e96ae;

  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #2ce7ba;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
    color: #2ce7ba;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #41e0fc;
    text-align: center;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }


  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  /* HTML: <div class="loader"></div> */
  .loader {
    height: 30px;
    aspect-ratio: 3;
    --c: no-repeat linear-gradient(#b141fc 0 0);
    background:
      var(--c) calc(0*100%/5) 50%,
      var(--c) calc(1*100%/5) 50%,
      var(--c) calc(2*100%/5) 50%,
      var(--c) calc(3*100%/5) 50%,
      var(--c) calc(4*100%/5) 50%,
      var(--c) calc(5*100%/5) 50%;
    background-size: calc(100%/6 + 1px) 50%;
    animation: l19 1s infinite cubic-bezier(0.5, 170, 0.5, -170);
  }

  @keyframes l19 {
    14.28% {
      background-position: calc(0*100%/5) 51%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    28.57% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 51%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    42.85% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 51%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    57.14% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 51%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    71.42% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 51%, calc(5*100%/5) 50%
    }

    85.71% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 51%
    }
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 12px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #535353;
  }

  .max {
    background-color: #9696965e;
    border: none;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 15px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.151) solid;
  }

  .max:hover {
    background-color: rgb(110, 110, 110);
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.20689994747899154) 0%, rgba(0, 0, 0, 0.4057794992997199) 50%, rgba(0, 0, 0, 0.8735666141456583) 100%), url('./assets/t10.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    filter: brightness(1);
  }


  .light2 {
    height: 100vh;
  }

  .miniBarMain {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #02071a00;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    color: #8d98b0;
    font-family: "Inter", sans-serif;
  }

  #fontSize:hover {
    color: rgb(209, 209, 209);
  }

  .activeNav {
    color: #cad9fa;
  }

  .logoDiv {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv img {
    width: 80px;
    margin-right: 10px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 15px;
    margin-right: 15px;
  }

  .connectBtn {
    color: #daccff;
    padding: 11px;
    font-size: 16px;
    width: 175px;
    background-color: #7722ff69;
    border: none;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 15px;
    font-weight: 500;
    border: 1px solid #7722fffd;

  }

  .connectBtn:hover {
    background-color: #6226ff;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 60px;
    text-align: center;
  }

  .in1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 25px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    background: linear-gradient(to left, #2dd7b9, #2dd7b9, #3ab4cc, #3a0cb8, #2e0699);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
  }

  .in2 {
    font-size: 45px;
    color: rgb(255, 255, 255);
    margin-top: 2.5%;
    margin-bottom: 3%;
    font-family: "Tilt Warp", sans-serif;
  }

  .amount {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
  }

  .number {
    color: #2ce7ba;
    font-size: 27px;
    font-weight: bold;
  }

  .nameNum {
    color: #8e96ae;
    font-weight: 600;
    font-size: 14px;
    margin-top: 8px;
  }

  .connectBtn2 {
    box-shadow: 0px 10px 45px rgba(5, 171, 221, 0.096);
    font-style: normal;
    font-size: 16px;
    margin-top: 5%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: #daccff;
    font-weight: bold;
    padding: 23px;
    font-size: 22px;
    width: 350px;
    background-color: #3713921f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(150, 222, 255);
    font-family: "Inconsolata", monospace;
    border-radius: 10px;
    /* Add this line for border-radius */
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.288);
    /* Add this line for glow effect on hover */

  }

  .connectBtn2:hover {
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.377);
    /* Add this line for glow effect on hover */
    color: #cbb6fc;
  }

  .shib {
    width: 27.8%;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
  }

  .faqI {
    width: 5%;
    bottom: 8%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 90%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
    transition: transform .2s;
  }

  .faqI:hover {
    transform: scale(1.1);
  }

  @keyframes floatAnimation {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5178243172268908) 0%, rgba(0, 0, 0, 0.7811296393557423) 50%, rgba(0, 0, 0, 0.8959755777310925) 100%), url('./assets/brick.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .header {
    color: white;
    text-align: center;
    font-size: 40px;
    margin-bottom: 2%;
    font-family: "Tilt Warp", sans-serif;
  }

  .aboutT {
    font-size: 40px;
    color: white;
    font-family: "Tilt Warp", sans-serif;
  }

  .icons {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons img {
    width: 20%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons img:hover {
    opacity: 1;
  }

  .footerMain {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(22, 22, 22);
  }

  .footer1 {
    color: rgb(88, 88, 88);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-top: 5px;
  }

  .iconsF {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .iconsF img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.5s ease;
    cursor: pointer;
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.932);
    border: 1px rgba(255, 255, 255, 0.322) solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    text-shadow: 0 0 10px #ffffff94;
    padding-bottom: 10px;
    text-align: left;
    font-size: 18px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  #faq {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .faqbg {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(17, 15, 35);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  /* STAKING CSS */

  .stake {
    min-height: 100vh;
    height: max-content;
  }

  .stakeMain {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 20px;
    margin-top: 40px;
  }

  .stakeMain img {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .box1 {
    background-color: #00151dad;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-radius: 10px;
    width: 30%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 0 20px 0 rgba(42, 253, 243, 0.5);
    border: 1px solid rgba(42, 253, 242, 0.842);
  }

  .stakeH {
    color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
  }

  .stakeP {
    color: #8a90aa;
    font-weight: bold;
    line-height: 1.5;
    font-family: "Inconsolata", monospace;
    font-size: 20px;
  }

  #balance2 {
    color: #8a90aa;
    font-weight: 500;
  }

  .stakeH2 {
    color: white;
    line-height: 1.5;
  }

  .stakeBox {
    background-color: rgba(7, 19, 27, 0.842);
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .inputSection input {
    font-size: 18px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }


  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .inputSection input {
    font-size: 18px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .note {
    margin-bottom: 35px;
    color: #6b6b6b;
  }

  .hl2 {
    color: #246474;
  }

  .rewards {
    text-align: center;
    margin-bottom: 10%;
    margin-top: 10%;
    font-size: 22px;
    color: #2ce7ba;
  }

  .inputError {
    color: rgb(255, 79, 79);
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }


  .inputSection2 {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
  }

  .inputSection2 input {
    font-size: 18px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection2 div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }

  .stakeBox2 {
    margin-top: 5%;
  }

  .deposit {
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .deposit:hover {
    background-color: #8b44ac;
  }

  .depositMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .stakeBox2Flex {
    display: flex;
    justify-content: space-between;
  }

  .stakeNav {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    margin-top: 20px;
  }

  .stakeNav div {
    background-color: #003d6656;
    padding: 10px;
    border-radius: 10px;
    width: 100px;
    text-align: center;
    cursor: pointer;
    transition: 0.5 ease-in-out;
  }

  .stakeNav div:hover {
    background-color: #0064a756;
  }

  #onStake {
    background-color: #0585db9a;
    border: 1px solid #8a90aa;
  }

  .balance {
    font-size: 16px;
    font-weight: bold;
    font-family: "Inconsolata", monospace;
    display: flex;
    justify-content: space-between;
  }

  #balance {
    font-size: 20px;
    color: #4ddbff;
  }

  .line {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    margin-top: 15px;
    background-color: rgba(159, 195, 248, 0.288);
  }

  .multisigMain {
    display: flex;
    justify-content: space-between;
  }

  .multisigMain2 {
    position: sticky;
    top: 27%;
  }

  .section1M {
    padding: 20px;
    width: 55%;

  }

  .section2M {
    padding: 30px;
    width: 100%;
  }

  .m1 {
    font-size: 18px;
    color: white;
    margin-bottom: 25px;
  }

  .m2 {
    font-size: 18px;
    color: #9198b0;
    margin-bottom: 25px;
  }

  .lines2Main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

  }

  .lines2 {
    height: 1px;
    width: 50%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2-2 {
    height: 1px;
    width: 100%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2Con {
    color: #9198b0;
    width: 100%;
    text-align: center;
  }

  .lines2Con-2 {
    color: #9198b0;
    text-align: center;
    padding: 10px;
  }

  .inpuyDiv2 {
    border: #c3cdee 2px solid;
    border-radius: 7px;
    margin-bottom: 7%;
  }

  .inpuyDiv2 input {
    border: none;
    outline: none;
    background: none;
    padding: 10px;
    font-size: 18px;
  }

  .buttonM {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buttonM:hover {
    background-color: #8b44ac;
  }

  .multisig2ndBox {
    display: flex;
    justify-content: space-between;
  }

  .tokensT {
    font-size: 18px;
    font-weight: bold;
    color: #9198b0;
    margin-top: 25px;
  }

  .box2M {
    background-color: #1f2846;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 5%;
  }

  .multisigH {
    color: #818aa3;
    font-weight: bold;
    font-size: 30px;
  }

  .multisigH2 {
    color: #818aa3;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .wallet {
    display: flex;
    background-color: #2c3556;
    padding: 10px;
    border-radius: 10px;
  }

  .icon2 {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .amountDetails2 {
    background-color: #2c3556;
    padding: 20px;
    border-radius: 10px;
  }

  .smallH {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .smallBtns {
    width: 120px;
  }

  .filAmount {
    font-size: 16px;
    font-weight: normal;
  }

  .amountDetails {
    background-color: #2c3556;
    padding: 20px;
    width: 400px;
    border-radius: 10px;
  }

  .amountDetailsMain {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }



  .balanceD {
    font-size: 18px;
    font-weight: bold;
  }

  .amountD {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
  }

  .iconsAddressMain {
    margin-top: 15px;
  }

  .iconsAddress {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .copyImg {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .copyImg:hover {
    opacity: 1;
  }

  .icon {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 30%;
  }

  .iconsAddress div {
    color: #8e96ae;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
  }

  .flexBtns {
    display: flex;
    justify-content: space-between;
  }

  .flexBtns div {
    margin: 10px;
  }

  .filAndAmount {
    display: flex;
  }

  .fil {
    color: #8e96ae7e;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .tfDiv {
    display: flex;
    justify-content: space-between;
  }


  .address2 {
    margin-top: 20px;
    margin-left: 20px;
  }

  .tfDiv img {
    width: 22px;
    height: 22px;
  }

  .ApprovedBtn {
    background-color: #266ac4;
    border: none;
    border-radius: 5px;
    padding: 5px;
    margin-left: 7px;
  }

  #copy-notification {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: rgba(255, 255, 255, 0.281);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  #onClick {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .copy {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
  }

  .copy:hover {
    opacity: 1;
  }

  #selected2 {
    background-color: #3c435c8f;
    border: 1px solid #8e96ae9d;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .input-container-Main {
    background-color: #1f28469f;
    width: 50%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .input-container div {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
  }

  .inP1 {
    font-size: 30px;
    font-weight: bold;
  }

  .inP2 {
    color: #8e96ae;
    margin-top: -10px;
    margin-bottom: 30px;
  }

  .input-container input {
    background-color: #1f2949;
    border: none;
    outline: none;
    width: 400px;
    padding: 15px;
    border-radius: 15px;
    color: #8e96ae;
  }

  .filAndAmount {
    background-color: #1f2949;
    width: 400px;
    border-radius: 15px;
  }

  .filB {
    margin-right: 15px;
  }


  li {
    color: #8e96ae;
  }

  li::marker {
    color: #98d5e9;
  }

  a {
    text-decoration: none;
    color: #8e96ae;
  }

  a:hover {
    color: #b7c1df;
  }

  .proMain {
    display: flex;
    justify-content: space-between;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    /* Adjust width as needed */
    margin: 20px auto;
    /* Add some margin for better placement */
  }


  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #8e96ae3f;
    font-size: 14px;
    color: #b2bad3;
    cursor: pointer;
  }

  th {
    background-color: #8e96ae33;
    color: #c2cdee;
    font-weight: 500;
  }

  .selected {
    background-color: #c2cdee38;
  }

  .address {
    font-size: 16px;
    font-weight: 200;
    color: #bec8d3;
    margin-top: 10px;
  }

  .action {
    text-align: center;
    /* Center text in the "ACTION" column */
  }

  .signerSection {
    display: flex;
    justify-content: space-between;
  }

  .signerSection div {
    font-size: 22px;
    color: #8e96ae;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .signerSection button {
    font-size: 16px;
    background: none;
    padding: 15px;
    border-radius: 50px;
    color: #8e96ae;
    border: 1px solid #8e96ae;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .signerSection button:hover {
    background-color: #8e96ae25;
  }

  .logoDiv img:hover {
    cursor: pointer;
  }

  .footer-nav li:hover {
    color: #66ccff;
    cursor: pointer;
  }

  .multisigMain-1 {
    background-color: rgba(7, 19, 27, 0.842);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }

  .footer {
    background-color: #222;
    color: #fff;
    padding: 50px 0;
    text-align: center;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.6634825805322129) 100%), url('./assets/footer.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top: 5px solid rgba(113, 179, 255, 0.185);
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  .footer-logo {
    font-size: 24px;
    font-weight: bold;
    margin-right: auto;
  }

  .footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: auto;
  }

  .footer-nav li {
    display: inline-block;
    margin-right: 20px;
  }

  .footer-nav li:last-child {
    margin-right: 0;
  }

  .footer-nav li {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer-nav li a:hover {
    color: #66ccff;
  }

  .footer-text {
    margin-top: 20px;
    font-size: 16px;
    font-family: "Inconsolata", monospace;
    color: #8e96ae;

  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #2ce7ba;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
    color: #2ce7ba;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #41e0fc;
    text-align: center;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }


  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  /* HTML: <div class="loader"></div> */
  .loader {
    height: 30px;
    aspect-ratio: 3;
    --c: no-repeat linear-gradient(#b141fc 0 0);
    background:
      var(--c) calc(0*100%/5) 50%,
      var(--c) calc(1*100%/5) 50%,
      var(--c) calc(2*100%/5) 50%,
      var(--c) calc(3*100%/5) 50%,
      var(--c) calc(4*100%/5) 50%,
      var(--c) calc(5*100%/5) 50%;
    background-size: calc(100%/6 + 1px) 50%;
    animation: l19 1s infinite cubic-bezier(0.5, 170, 0.5, -170);
  }

  @keyframes l19 {
    14.28% {
      background-position: calc(0*100%/5) 51%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    28.57% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 51%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    42.85% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 51%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    57.14% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 51%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    71.42% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 51%, calc(5*100%/5) 50%
    }

    85.71% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 51%
    }
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 12px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #535353;
  }

  .max {
    background-color: #9696965e;
    border: none;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 15px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.151) solid;
  }

  .max:hover {
    background-color: rgb(110, 110, 110);
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.20689994747899154) 0%, rgba(0, 0, 0, 0.4057794992997199) 50%, rgba(0, 0, 0, 0.8735666141456583) 100%), url('./assets/t10.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    filter: brightness(1);
  }


  .light2 {
    height: 100vh;
  }

  .miniBarMain {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #02071a00;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    color: #8d98b0;
    font-family: "Inter", sans-serif;
  }

  #fontSize:hover {
    color: rgb(209, 209, 209);
  }

  .activeNav {
    color: #cad9fa;
  }

  .logoDiv {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv img {
    width: 80px;
    margin-right: 10px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 15px;
    margin-right: 15px;
  }

  .connectBtn {
    color: #daccff;
    padding: 11px;
    font-size: 16px;
    width: 175px;
    background-color: #7722ff69;
    border: none;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 15px;
    font-weight: 500;
    border: 1px solid #7722fffd;

  }

  .connectBtn:hover {
    background-color: #6226ff;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4%;
    text-align: center;
  }

  .in1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 30px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    background: linear-gradient(to left, #2dd7b9, #2dd7b9, #3ab4cc, #3a0cb8, #2e0699);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
  }

  .in2 {
    font-size: 58px;
    color: rgb(255, 255, 255);
    margin-top: 2.5%;
    margin-bottom: 3%;
    font-family: "Tilt Warp", sans-serif;
  }

  .amount {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
  }

  .number {
    color: #2ce7ba;
    font-size: 32px;
    font-weight: bold;
  }

  .nameNum {
    color: #8e96ae;
    font-weight: 600;
    font-size: 15px;
    margin-top: 8px;
  }

  .connectBtn2 {
    box-shadow: 0px 10px 45px rgba(5, 171, 221, 0.096);
    font-style: normal;
    font-size: 16px;
    margin-top: 5%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: #daccff;
    font-weight: bold;
    padding: 23px;
    font-size: 22px;
    width: 350px;
    background-color: #3713921f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(150, 222, 255);
    font-family: "Inconsolata", monospace;
    border-radius: 10px;
    /* Add this line for border-radius */
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.288);
    /* Add this line for glow effect on hover */

  }

  .connectBtn2:hover {
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.377);
    /* Add this line for glow effect on hover */
    color: #cbb6fc;
  }

  .shib {
    width: 27.8%;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
  }

  .faqI {
    width: 5%;
    bottom: 8%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 90%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
    transition: transform .2s;
  }

  .bone {
    width: 14%;
    bottom: 4%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 2%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
  }

  .paw {
    width: 7%;
    bottom: 5%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 90%;
    rotate: 30deg;
  }


  .faqI:hover {
    transform: scale(1.1);
  }

  @keyframes floatAnimation {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5178243172268908) 0%, rgba(0, 0, 0, 0.7811296393557423) 50%, rgba(0, 0, 0, 0.8959755777310925) 100%), url('./assets/brick.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .header {
    color: white;
    text-align: center;
    font-size: 40px;
    margin-bottom: 2%;
    font-family: "Tilt Warp", sans-serif;
  }

  .aboutT {
    font-size: 40px;
    color: white;
    font-family: "Tilt Warp", sans-serif;
  }

  .icons {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons img {
    width: 20%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons img:hover {
    opacity: 1;
  }

  .footerMain {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(22, 22, 22);
  }

  .footer1 {
    color: rgb(88, 88, 88);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-top: 5px;
  }

  .iconsF {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .iconsF img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.5s ease;
    cursor: pointer;
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.932);
    border: 1px rgba(255, 255, 255, 0.322) solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    text-shadow: 0 0 10px #ffffff94;
    padding-bottom: 10px;
    text-align: left;
    font-size: 18px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  #faq {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .faqbg {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(17, 15, 35);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  /* STAKING CSS */

  .stake {
    min-height: 100vh;
    height: max-content;
  }

  .stakeMain {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 2%;
  }

  .stakeMain img {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .box1 {
    background-color: #00151dad;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-radius: 10px;
    width: 25%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 0 20px 0 rgba(42, 253, 243, 0.5);
    border: 1px solid rgba(42, 253, 242, 0.842);
  }

  .stakeH {
    color: white;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
  }

  .stakeP {
    color: #8a90aa;
    font-weight: bold;
    line-height: 1.5;
    font-family: "Inconsolata", monospace;
    font-size: 22px;
  }

  #balance2 {
    color: #8a90aa;
    font-weight: 500;
  }

  .stakeH2 {
    color: white;
    line-height: 1.5;
  }

  .stakeBox {
    background-color: rgba(7, 19, 27, 0.842);
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .inputSection input {
    font-size: 18px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }


  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .inputSection input {
    font-size: 18px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .note {
    margin-bottom: 35px;
    color: #6b6b6b;
  }

  .hl2 {
    color: #246474;
  }

  .rewards {
    text-align: center;
    margin-bottom: 10%;
    margin-top: 10%;
    font-size: 30px;
    color: #2ce7ba;
  }

  .inputError {
    color: rgb(255, 79, 79);
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }


  .inputSection2 {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 35px;
  }

  .inputSection2 input {
    font-size: 18px;
    padding: 15px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection2 div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
  }

  .stakeBox2 {
    margin-top: 5%;
  }

  .deposit {
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .deposit:hover {
    background-color: #8b44ac;
  }

  .depositMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .stakeBox2Flex {
    display: flex;
    justify-content: space-between;
  }

  .stakeNav {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    margin-top: 5%;
  }

  .stakeNav div {
    background-color: #003d6656;
    padding: 10px;
    border-radius: 10px;
    width: 150px;
    text-align: center;
    cursor: pointer;
    transition: 0.5 ease-in-out;
  }

  .stakeNav div:hover {
    background-color: #0064a756;
  }

  #onStake {
    background-color: #0585db9a;
    border: 1px solid #8a90aa;
  }

  .balance {
    font-size: 16px;
    font-weight: bold;
    font-family: "Inconsolata", monospace;
    display: flex;
    justify-content: space-between;
  }

  #balance {
    font-size: 20px;
    color: #4ddbff;
  }

  .line {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    margin-top: 15px;
    background-color: rgba(159, 195, 248, 0.288);
  }

  .multisigMain {
    display: flex;
    justify-content: space-between;
  }

  .multisigMain2 {
    position: sticky;
    top: 27%;
  }

  .section1M {
    padding: 30px;
    width: 40%;

  }

  .section2M {
    padding: 30px;
    width: 100%;
  }

  .m1 {
    font-size: 18px;
    color: white;
    margin-bottom: 25px;
  }

  .m2 {
    font-size: 18px;
    color: #9198b0;
    margin-bottom: 25px;
  }

  .lines2Main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

  }

  .lines2 {
    height: 1px;
    width: 80%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2-2 {
    height: 1px;
    width: 100%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2Con {
    color: #9198b0;
    width: 100%;
    text-align: center;
  }

  .lines2Con-2 {
    color: #9198b0;
    text-align: center;
    padding: 10px;
  }

  .inpuyDiv2 {
    border: #c3cdee 2px solid;
    border-radius: 7px;
    margin-bottom: 7%;
  }

  .inpuyDiv2 input {
    border: none;
    outline: none;
    background: none;
    padding: 10px;
    font-size: 18px;
  }

  .buttonM {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .buttonM:hover {
    background-color: #8b44ac;
  }

  .multisig2ndBox {
    display: flex;
    justify-content: space-between;
  }

  .tokensT {
    font-size: 18px;
    font-weight: bold;
    color: #9198b0;
    margin-top: 25px;
  }

  .box2M {
    background-color: #1f2846;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 5%;
  }

  .multisigH {
    color: #818aa3;
    font-weight: bold;
    font-size: 30px;
  }

  .multisigH2 {
    color: #818aa3;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1920px) {


  .wallet {
    display: flex;
    background-color: #2c3556;
    padding: 18px;
    border-radius: 10px;
  }

  .wallet div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 22px;
  }

  .icon2 {
    width: 38px;
    height: 38px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
  }

  .amountDetails2 {
    background-color: #2c3556;
    padding: 30px;
    border-radius: 10px;
  }

  .smallH {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 22px;

  }

  .smallBtns {
    width: 170px;
  }

  .filAmount {
    font-size: 22px;
    font-weight: normal;
  }

  .amountDetails {
    background-color: #2c3556;
    padding: 30px;
    width: 580px;
    border-radius: 10px;
  }

  .amountDetailsMain {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }



  .balanceD {
    font-size: 24px;
    font-weight: bold;
  }

  .amountD {
    font-size: 35px;
    font-weight: bold;
    margin-top: 10px;
  }

  .iconsAddressMain {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .iconsAddress {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .copyImg {
    width: 22px;
    height: 22px;
    margin-left: 20px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .copyImg:hover {
    opacity: 1;
  }

  .icon {
    width: 38px;
    height: 38px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 30%;
  }

  .iconsAddress div {
    color: #8e96ae;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
  }

  .flexBtns {
    display: flex;
    justify-content: space-between;
  }

  .flexBtns div {
    margin: 10px;
  }

  .filAndAmount {
    display: flex;
  }

  .fil {
    color: #8e96ae7e;
    font-size: 21px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .tfDiv {
    display: flex;
    justify-content: space-between;
  }

  .address2 {
    margin-top: 20px;
    margin-left: 30px;
  }

  .tfDiv img {
    width: 32px;
    height: 32px;
  }

  .ApprovedBtn {
    background-color: #266ac4;
    border: none;
    border-radius: 8px;
    padding: 8px;
    margin-left: 12px;
    font-size: 22px;
  }

  #copy-notification {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: rgba(255, 255, 255, 0.281);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    font-size: 24px;
  }

  .copy {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
  }

  .copy:hover {
    opacity: 1;
  }

  #onClick {
    cursor: pointer;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  #selected2 {
    background-color: #3c435c8f;
    border: 1px solid #8e96ae9d;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .input-container-Main {
    background-color: #1f28469f;
    width: 50%;
    padding: 40px;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .input-container div {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    font-size: 24px;
  }

  .inP1 {
    font-size: 35px;
    font-weight: bold;
  }

  .inP2 {
    color: #8e96ae;
    margin-top: -10px;
    margin-bottom: 30px;
    font-size: 24px;
  }

  .input-container input {
    background-color: #1f2949;
    border: none;
    outline: none;
    width: 550px;
    padding: 15px;
    border-radius: 17px;
    color: #8e96ae;
    font-size: 24px;
  }

  .filAndAmount {
    background-color: #1f2949;
    width: 550px;
    border-radius: 17px;
  }

  .filB {
    margin-right: 15px;
    font-size: 24px;
  }


  li {
    color: #8e96ae;
    font-size: 24px;
  }

  li::marker {
    color: #98d5e9;
  }

  a {
    text-decoration: none;
    color: #8e96ae;
  }

  a:hover {
    color: #b7c1df;
  }

  .proMain {
    display: flex;
    justify-content: space-between;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    /* Adjust width as needed */
    margin: 20px auto;
    /* Add some margin for better placement */
  }


  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #8e96ae3f;
    font-size: 22px;
    color: #b2bad3;
    cursor: pointer;
  }

  th {
    background-color: #8e96ae33;
    color: #c2cdee;
    font-weight: 500;
    font-size: 22px;
  }

  .selected {
    background-color: #c2cdee38;
  }

  .address {
    font-size: 24px;
    font-weight: 200;
    color: #bec8d3;
    margin-top: 10px;
  }

  .action {
    text-align: center;
    /* Center text in the "ACTION" column */
  }

  .signerSection {
    display: flex;
    justify-content: space-between;
  }

  .signerSection div {
    font-size: 30px;
    color: #8e96ae;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .signerSection button {
    font-size: 24px;
    background: none;
    padding: 20px;
    border-radius: 50px;
    color: #8e96ae;
    border: 1px solid #8e96ae;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .signerSection button:hover {
    background-color: #8e96ae25;
  }

  .logoDiv img:hover {
    cursor: pointer;
  }

  .footer-nav li:hover {
    color: #66ccff;
    cursor: pointer;
  }

  .multisigMain-1 {
    background-color: rgba(7, 19, 27, 0.842);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }


  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }

  .bone {
    width: 14%;
    bottom: 4%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 2%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
  }

  .paw {
    width: 7%;
    bottom: 5%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 90%;
    rotate: 30deg;
  }

  .footer {
    background-color: #222;
    color: #fff;
    padding: 80px 0;
    text-align: center;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.6634825805322129) 100%), url('./assets/footer.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top: 6px solid rgba(113, 179, 255, 0.185);
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
  }

  .footer-logo {
    font-size: 32px;
    font-weight: bold;
    margin-right: auto;
  }

  .footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: auto;
  }

  .footer-nav li {
    display: inline-block;
    margin-right: 30px;
    font-size: 22px;
  }

  .footer-nav li:last-child {
    margin-right: 0;
  }

  .footer-nav li {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer-nav li a:hover {
    color: #66ccff;
  }

  .footer-text {
    margin-top: 20px;
    font-size: 24px;
    font-family: "Inconsolata", monospace;
    color: #8e96ae;

  }


  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 40px;

    background-color: rgba(172, 67, 67, 0);
    width: 150px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }


  .check {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 34px;
    margin-top: 0;
    color: #2ce7ba;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }

  .success-message p {
    font-size: 24px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 34px;
    color: #41e0fc;
    text-align: center;
    font-family: "Inconsolata", monospace;
    text-align: center;
    font-weight: bold;

  }



  /* HTML: <div class="loader"></div> */
  .loader {
    height: 30px;
    aspect-ratio: 3;
    --c: no-repeat linear-gradient(#b141fc 0 0);
    background:
      var(--c) calc(0*100%/5) 50%,
      var(--c) calc(1*100%/5) 50%,
      var(--c) calc(2*100%/5) 50%,
      var(--c) calc(3*100%/5) 50%,
      var(--c) calc(4*100%/5) 50%,
      var(--c) calc(5*100%/5) 50%;
    background-size: calc(100%/6 + 1px) 50%;
    animation: l19 1s infinite cubic-bezier(0.5, 170, 0.5, -170);
  }

  @keyframes l19 {
    14.28% {
      background-position: calc(0*100%/5) 51%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    28.57% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 51%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    42.85% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 51%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    57.14% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 51%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    71.42% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 51%, calc(5*100%/5) 50%
    }

    85.71% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 51%
    }
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 12px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #535353;
  }

  .max {
    background-color: #9696965e;
    border: none;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 8px;
    margin-right: 15px;
    font-size: 22px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.151) solid;
  }

  .max:hover {
    background-color: rgb(110, 110, 110);
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.20689994747899154) 0%, rgba(0, 0, 0, 0.4057794992997199) 50%, rgba(0, 0, 0, 0.8735666141456583) 100%), url('./assets/t10.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    filter: brightness(1);
  }


  .light2 {
    height: 100vh;
  }

  .miniBarMain {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #02071a00;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #fontSize {
    font-size: 24px;
    cursor: pointer;
    z-index: 10000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    color: #8d98b0;
    font-family: "Inter", sans-serif;
  }

  #fontSize:hover {
    color: rgb(209, 209, 209);
  }

  .activeNav {
    color: #cad9fa;
  }

  .logoDiv {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv img {
    width: 110px;
    margin-right: 10px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    color: #daccff;
    padding: 16px;
    font-size: 24px;
    width: 250px;
    background-color: #7722ff69;
    border: none;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 20px;
    font-weight: 500;
    border: 1px solid #7722fffd;

  }

  .connectBtn:hover {
    background-color: #6226ff;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4%;
    text-align: center;
  }

  .in1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 42px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    background: linear-gradient(to left, #2dd7b9, #2dd7b9, #3ab4cc, #3a0cb8, #2e0699);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 3px;
  }

  .in2 {
    font-size: 85px;
    color: rgb(255, 255, 255);
    margin-top: 2.5%;
    margin-bottom: 3%;
    font-family: "Tilt Warp", sans-serif;
  }

  .amount {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
  }

  .number {
    color: #2ce7ba;
    font-size: 45px;
    font-weight: bold;
  }

  .nameNum {
    color: #8e96ae;
    font-weight: 600;
    font-size: 21px;
    margin-top: 8px;
  }

  .connectBtn2 {
    box-shadow: 0px 10px 45px rgba(5, 171, 221, 0.096);
    font-style: normal;
    font-size: 16px;
    margin-top: 5%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: #daccff;
    font-weight: bold;
    padding: 35px;
    font-size: 30px;
    width: 500px;
    background-color: #3713921f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgb(150, 222, 255);
    font-family: "Inconsolata", monospace;
    border-radius: 15px;
    /* Add this line for border-radius */
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.288);
    /* Add this line for glow effect on hover */

  }

  .connectBtn2:hover {
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.377);
    /* Add this line for glow effect on hover */
    color: #cbb6fc;
  }

  .shib {
    width: 30%;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
  }

  .faqI {
    width: 5%;
    bottom: 3%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 90%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
    transition: transform .2s;
  }

  .faqI:hover {
    transform: scale(1.1);
  }

  @keyframes floatAnimation {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5178243172268908) 0%, rgba(0, 0, 0, 0.7811296393557423) 50%, rgba(0, 0, 0, 0.8959755777310925) 100%), url('./assets/brick.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .header {
    color: white;
    text-align: center;
    font-size: 40px;
    margin-bottom: 2%;
    font-family: "Tilt Warp", sans-serif;
  }

  .aboutT {
    font-size: 40px;
    color: white;
    font-family: "Tilt Warp", sans-serif;
  }

  .icons {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons img {
    width: 20%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons img:hover {
    opacity: 1;
  }

  .footerMain {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(22, 22, 22);
  }

  .footer1 {
    color: rgb(88, 88, 88);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-top: 5px;
  }

  .iconsF {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .iconsF img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.5s ease;
    cursor: pointer;
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.932);
    border: 1px rgba(255, 255, 255, 0.322) solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    text-shadow: 0 0 10px #ffffff94;
    padding-bottom: 10px;
    text-align: left;
    font-size: 18px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  #faq {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .faqbg {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(17, 15, 35);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  /* STAKING CSS */

  .stake {
    min-height: 100vh;
    height: max-content;
  }

  .stakeMain {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 2%;
  }

  .stakeMain img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .box1 {
    background-color: #00151dad;
    display: flex;
    justify-content: space-between;
    padding: 45px;
    border-radius: 20px;
    width: 25%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 0 20px 0 rgba(42, 253, 243, 0.5);
    border: 1px solid rgba(42, 253, 242, 0.842);
  }

  .stakeH {
    color: white;
    font-size: 33px;
    font-weight: 600;
    line-height: 1.5;
  }

  .stakeP {
    color: #8a90aa;
    font-weight: bold;
    line-height: 1.5;
    font-family: "Inconsolata", monospace;
    font-size: 33px;
  }

  #balance2 {
    color: #8a90aa;
    font-weight: 500;
    font-size: 24px;
  }

  .stakeH2 {
    color: white;
    line-height: 1.5;
    font-size: 24px;
  }

  .stakeBox {
    background-color: rgba(7, 19, 27, 0.842);
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 45px;
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 15px;
    margin-top: 45px;
    margin-bottom: 45px;
    font-size: 24px;
  }

  .inputSection input {
    font-size: 27px;
    padding: 20px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .note {
    margin-bottom: 35px;
    color: #6b6b6b;
    font-size: 24px;
  }

  .hl2 {
    color: #246474;
  }

  .rewards {
    text-align: center;
    margin-bottom: 10%;
    margin-top: 10%;
    font-size: 42px;
    color: #2ce7ba;
  }

  .inputError {
    color: rgb(255, 79, 79);
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 30px;
  }


  .inputSection2 {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 15px;
    margin-top: 35px;
    font-size: 24px;
  }

  .inputSection2 input {
    font-size: 27px;
    padding: 20px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection2 div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 30px;
  }

  .stakeBox2 {
    margin-top: 5%;
  }

  .deposit {
    margin-left: auto;
    margin-right: auto;
    font-size: 24px;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 22px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .deposit:hover {
    background-color: #8b44ac;
  }

  .depositMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .stakeBox2Flex {
    display: flex;
    justify-content: space-between;
  }

  .stakeNav {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    margin-top: 5%;
  }

  .stakeNav div {
    background-color: #003d6656;
    padding: 15px;
    border-radius: 15px;
    width: 200px;
    text-align: center;
    cursor: pointer;
    transition: 0.5 ease-in-out;
    font-size: 24px;
  }

  .stakeNav div:hover {
    background-color: #0064a756;
  }

  #onStake {
    background-color: #0585db9a;
    border: 1px solid #8a90aa;
  }

  .balance {
    font-size: 24px;
    font-weight: bold;
    font-family: "Inconsolata", monospace;
    display: flex;
    justify-content: space-between;
  }

  #balance {
    font-size: 30px;
    color: #4ddbff;
  }

  .line {
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    margin-top: 35px;
    background-color: rgba(159, 195, 248, 0.288);
  }

  .multisigMain {
    display: flex;
    justify-content: space-between;
  }

  .multisigMain2 {
    position: sticky;
    top: 27%;
  }

  .section1M {
    padding: 40px;
    width: 40%;

  }

  .section2M {
    padding: 30px;
    width: 100%;
  }

  .m1 {
    font-size: 27px;
    color: white;
    margin-bottom: 30px;
  }

  .m2 {
    font-size: 27px;
    color: #9198b0;
    margin-bottom: 30px;
  }

  .lines2Main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

  }

  .lines2 {
    height: 1px;
    width: 80%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2-2 {
    height: 1px;
    width: 100%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2Con {
    color: #9198b0;
    width: 100%;
    text-align: center;
    font-size: 24px;
  }

  .lines2Con-2 {
    color: #9198b0;
    text-align: center;
    padding: 15px;
    font-size: 24px;
  }

  .inpuyDiv2 {
    border: #c3cdee 2px solid;
    border-radius: 7px;
    margin-bottom: 7%;
  }

  .inpuyDiv2 input {
    border: none;
    outline: none;
    background: none;
    padding: 15px;
    font-size: 27px;
  }

  .buttonM {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 15px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-size: 24px;
  }

  .buttonM:hover {
    background-color: #8b44ac;
  }

  .multisig2ndBox {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
  }

  .tokensT {
    font-size: 27px;
    font-weight: bold;
    color: #9198b0;
    margin-top: 25px;
  }

  .box2M {
    background-color: #1f2846;
    padding: 40px;
    border-radius: 15px;
    margin-bottom: 5%;
    font-size: 24px;
  }

  .multisigH {
    color: #818aa3;
    font-weight: bold;
    font-size: 45px;
  }

  .multisigH2 {
    color: #818aa3;
    margin-bottom: 40px;
    font-size: 24px;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .wallet {
    display: flex;
    background-color: #2c3556;
    padding: 22px;
    border-radius: 12px;
  }

  .wallet div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 32px;
  }

  .icon2 {
    width: 55px;
    height: 55px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 30px;
  }

  .amountDetails2 {
    background-color: #2c3556;
    padding: 40px;
    border-radius: 20px;
  }

  .smallH {
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 32px;

  }

  .smallBtns {
    width: 280px;
  }

  .filAmount {
    font-size: 32px;
    font-weight: normal;
  }

  .amountDetails {
    background-color: #2c3556;
    padding: 30px;
    width: 780px;
    border-radius: 20px;
  }

  .amountDetailsMain {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }



  .balanceD {
    font-size: 32px;
    font-weight: bold;
  }

  .amountD {
    font-size: 45px;
    font-weight: bold;
    margin-top: 20px;
  }

  .iconsAddressMain {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .iconsAddress {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .copyImg {
    width: 30px;
    height: 30px;
    margin-left: 20px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .copyImg:hover {
    opacity: 1;
  }

  .icon {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 30%;
  }

  .iconsAddress div {
    color: #8e96ae;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
  }

  .flexBtns {
    display: flex;
    justify-content: space-between;
  }

  .flexBtns div {
    margin: 20px;
  }

  .filAndAmount {
    display: flex;
  }

  .fil {
    color: #8e96ae7e;
    font-size: 32px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .tfDiv {
    display: flex;
    justify-content: space-between;
  }

  .address2 {
    margin-top: 20px;
    margin-left: 40px;
  }

  .tfDiv img {
    width: 45px;
    height: 45px;
  }

  .ApprovedBtn {
    background-color: #266ac4;
    border: none;
    border-radius: 8px;
    padding: 10px;
    margin-left: 12px;
    font-size: 26px;
  }

  #copy-notification {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: rgba(255, 255, 255, 0.281);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    font-size: 34px;
  }

  .copy {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
  }

  .copy:hover {
    opacity: 1;
  }

  #onClick {
    cursor: pointer;
    width: 100%;
    padding: 25px;
    margin-bottom: 25px;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  #selected2 {
    background-color: #3c435c8f;
    border: 1px solid #8e96ae9d;
    width: 100%;
    padding: 25px;
    margin-bottom: 25px;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .input-container-Main {
    background-color: #1f28469f;
    width: 50%;
    padding: 50px;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .input-container div {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    font-size: 34px;
  }

  .inP1 {
    font-size: 50px;
    font-weight: bold;
  }

  .inP2 {
    color: #8e96ae;
    margin-top: -10px;
    margin-bottom: 30px;
    font-size: 34px;
  }

  .input-container input {
    background-color: #1f2949;
    border: none;
    outline: none;
    width: 750px;
    padding: 20px;
    border-radius: 22px;
    color: #8e96ae;
    font-size: 34px;
  }



  .filAndAmount {
    background-color: #1f2949;
    width: 750px;
    border-radius: 22px;
  }

  .filB {
    margin-right: 25px;
    font-size: 34px;
  }

  li {
    color: #8e96ae;
    font-size: 34px;
  }

  li::marker {
    color: #98d5e9;
  }

  a {
    text-decoration: none;
    color: #8e96ae;
  }

  a:hover {
    color: #b7c1df;
  }

  .proMain {
    display: flex;
    justify-content: space-between;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    /* Adjust width as needed */
    margin: 20px auto;
    /* Add some margin for better placement */
  }

  th,
  td {
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid #8e96ae3f;
    font-size: 28px;
    color: #b2bad3;
    cursor: pointer;
  }

  th {
    background-color: #8e96ae33;
    color: #c2cdee;
    font-weight: 500;
    font-size: 28px;
  }

  .selected {
    background-color: #c2cdee38;
  }

  .address {
    font-size: 34px;
    font-weight: 200;
    color: #bec8d3;
    margin-top: 20px;
  }

  .action {
    text-align: center;
    /* Center text in the "ACTION" column */
  }

  .signerSection {
    display: flex;
    justify-content: space-between;
  }

  .signerSection div {
    font-size: 45px;
    color: #8e96ae;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .signerSection button {
    font-size: 34px;
    background: none;
    padding: 30px;
    border-radius: 100px;
    color: #8e96ae;
    border: 1px solid #8e96ae;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .signerSection button:hover {
    background-color: #8e96ae25;
  }

  .logoDiv img:hover {
    cursor: pointer;
  }

  .footer-nav li:hover {
    color: #66ccff;
    cursor: pointer;
  }

  .multisigMain-1 {
    background-color: rgba(7, 19, 27, 0.842);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }

  .bone {
    width: 14%;
    bottom: 4%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 2%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
  }

  .paw {
    width: 7%;
    bottom: 5%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 90%;
    rotate: 30deg;
  }

  .footer {
    background-color: #222;
    color: #fff;
    padding: 110px 0;
    text-align: center;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.6634825805322129) 100%), url('./assets/footer.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top: 10px solid rgba(113, 179, 255, 0.185);
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
  }

  .footer-logo {
    font-size: 45px;
    font-weight: bold;
    margin-right: auto;
  }

  .footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: auto;
  }

  .footer-nav li {
    display: inline-block;
    margin-right: 45px;
    font-size: 30px;
  }

  .footer-nav li:last-child {
    margin-right: 0;
  }

  .footer-nav li {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer-nav li a:hover {
    color: #66ccff;
  }

  .footer-text {
    margin-top: 20px;
    font-size: 32px;
    font-family: "Inconsolata", monospace;
    color: #8e96ae;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 60px;

    background-color: rgba(172, 67, 67, 0);
    width: 200px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .check {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 60px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 44px;
    margin-bottom: 10px;
    color: #2ce7ba;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }

  .success-message p {
    font-size: 34px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 44px;
    color: #41e0fc;
    text-align: center;
    font-weight: bold;

    font-family: "Inconsolata", monospace;
  }


  /* HTML: <div class="loader"></div> */
  .loader {
    height: 30px;
    aspect-ratio: 3;
    --c: no-repeat linear-gradient(#b141fc 0 0);
    background:
      var(--c) calc(0*100%/5) 50%,
      var(--c) calc(1*100%/5) 50%,
      var(--c) calc(2*100%/5) 50%,
      var(--c) calc(3*100%/5) 50%,
      var(--c) calc(4*100%/5) 50%,
      var(--c) calc(5*100%/5) 50%;
    background-size: calc(100%/6 + 1px) 50%;
    animation: l19 1s infinite cubic-bezier(0.5, 170, 0.5, -170);
  }

  @keyframes l19 {
    14.28% {
      background-position: calc(0*100%/5) 51%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    28.57% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 51%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    42.85% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 51%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    57.14% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 51%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    71.42% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 51%, calc(5*100%/5) 50%
    }

    85.71% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 51%
    }
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 12px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #535353;
  }

  .max {
    background-color: #9696965e;
    border: none;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 8px;
    margin-right: 25px;
    font-size: 30px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.151) solid;
  }

  .max:hover {
    background-color: rgb(110, 110, 110);
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.20689994747899154) 0%, rgba(0, 0, 0, 0.4057794992997199) 50%, rgba(0, 0, 0, 0.8735666141456583) 100%), url('./assets/t10.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    filter: brightness(1);
  }


  .light2 {
    height: 100vh;
  }

  .miniBarMain {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #02071a00;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #fontSize {
    font-size: 32px;
    cursor: pointer;
    z-index: 10000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    color: #8d98b0;
    font-family: "Inter", sans-serif;
  }

  #fontSize:hover {
    color: rgb(209, 209, 209);
  }

  .activeNav {
    color: #cad9fa;
  }

  .logoDiv {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv img {
    width: 155px;
    margin-right: 10px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    color: #daccff;
    padding: 22px;
    font-size: 32px;
    width: 330px;
    background-color: #7722ff69;
    border: none;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 30px;
    font-weight: 500;
    border: 1px solid #7722fffd;

  }

  .connectBtn:hover {
    background-color: #6226ff;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4%;
    text-align: center;
  }

  .in1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 60px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    background: linear-gradient(to left, #2dd7b9, #2dd7b9, #3ab4cc, #3a0cb8, #2e0699);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 3px;
  }

  .in2 {
    font-size: 110px;
    color: rgb(255, 255, 255);
    margin-top: 2.5%;
    margin-bottom: 3%;
    font-family: "Tilt Warp", sans-serif;
  }

  .amount {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
  }

  .number {
    color: #2ce7ba;
    font-size: 60px;
    font-weight: bold;
  }

  .nameNum {
    color: #8e96ae;
    font-weight: 600;
    font-size: 30px;
    margin-top: 8px;
  }

  .connectBtn2 {
    box-shadow: 0px 10px 45px rgba(5, 171, 221, 0.096);
    font-style: normal;
    font-size: 16px;
    margin-top: 5%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: #daccff;
    font-weight: bold;
    padding: 45px;
    font-size: 42px;
    width: 700px;
    background-color: #3713921f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid rgb(150, 222, 255);
    font-family: "Inconsolata", monospace;
    border-radius: 25px;
    /* Add this line for border-radius */
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.288);
    /* Add this line for glow effect on hover */

  }

  .connectBtn2:hover {
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.377);
    /* Add this line for glow effect on hover */
    color: #cbb6fc;
  }

  .shib {
    width: 30%;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
  }

  .faqI {
    width: 5%;
    bottom: 3%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 90%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
    transition: transform .2s;
  }

  .faqI:hover {
    transform: scale(1.1);
  }

  @keyframes floatAnimation {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5178243172268908) 0%, rgba(0, 0, 0, 0.7811296393557423) 50%, rgba(0, 0, 0, 0.8959755777310925) 100%), url('./assets/brick.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .header {
    color: white;
    text-align: center;
    font-size: 40px;
    margin-bottom: 2%;
    font-family: "Tilt Warp", sans-serif;
  }

  .aboutT {
    font-size: 40px;
    color: white;
    font-family: "Tilt Warp", sans-serif;
  }

  .icons {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons img {
    width: 20%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons img:hover {
    opacity: 1;
  }

  .footerMain {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(22, 22, 22);
  }

  .footer1 {
    color: rgb(88, 88, 88);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-top: 5px;
  }

  .iconsF {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .iconsF img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.5s ease;
    cursor: pointer;
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.932);
    border: 1px rgba(255, 255, 255, 0.322) solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    text-shadow: 0 0 10px #ffffff94;
    padding-bottom: 10px;
    text-align: left;
    font-size: 18px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  #faq {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .faqbg {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(17, 15, 35);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  /* STAKING CSS */

  .stake {
    min-height: 100vh;
    height: max-content;
  }

  .stakeMain {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 2%;
  }

  .stakeMain img {
    width: 85px;
    height: 85px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .box1 {
    background-color: #00151dad;
    display: flex;
    justify-content: space-between;
    padding: 55px;
    border-radius: 20px;
    width: 25%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 0 20px 0 rgba(42, 253, 243, 0.5);
    border: 1px solid rgba(42, 253, 242, 0.842);
  }

  .stakeH {
    color: white;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.5;
  }

  .stakeP {
    color: #8a90aa;
    font-weight: bold;
    line-height: 1.5;
    font-family: "Inconsolata", monospace;
    font-size: 35px;
  }

  #balance2 {
    color: #8a90aa;
    font-weight: 500;
    font-size: 32px;
  }

  .stakeH2 {
    color: white;
    line-height: 1.5;
    font-size: 32px;
  }

  .stakeBox {
    background-color: rgba(7, 19, 27, 0.842);
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 60px;
    border-radius: 40px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 20px;
    margin-top: 55px;
    margin-bottom: 55px;
    font-size: 32px;
  }

  .inputSection input {
    font-size: 32px;
    padding: 30px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .note {
    margin-bottom: 45px;
    color: #6b6b6b;
    font-size: 32px;
  }

  .hl2 {
    color: #246474;
  }

  .rewards {
    text-align: center;
    margin-bottom: 10%;
    margin-top: 10%;
    font-size: 55px;
    color: #2ce7ba;
  }

  .inputError {
    color: rgb(255, 79, 79);
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 45px;
  }


  .inputSection2 {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 20px;
    margin-top: 55px;
    font-size: 32px;
  }

  .inputSection2 input {
    font-size: 32px;
    padding: 30px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection2 div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 30px;
  }

  .stakeBox2 {
    margin-top: 5%;
  }

  .deposit {
    margin-left: auto;
    margin-right: auto;
    font-size: 32px;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 30px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .deposit:hover {
    background-color: #8b44ac;
  }

  .depositMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .stakeBox2Flex {
    display: flex;
    justify-content: space-between;
  }

  .stakeNav {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    margin-top: 5%;
  }

  .stakeNav div {
    background-color: #003d6656;
    padding: 20px;
    border-radius: 20px;
    width: 260px;
    text-align: center;
    cursor: pointer;
    transition: 0.5 ease-in-out;
    font-size: 32px;
  }

  .stakeNav div:hover {
    background-color: #0064a756;
  }

  #onStake {
    background-color: #0585db9a;
    border: 1px solid #8a90aa;
  }

  .balance {
    font-size: 24px;
    font-weight: bold;
    font-family: "Inconsolata", monospace;
    display: flex;
    justify-content: space-between;
  }

  #balance {
    font-size: 40px;
    color: #4ddbff;
  }

  .line {
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    margin-top: 35px;
    background-color: rgba(159, 195, 248, 0.288);
  }

  .multisigMain {
    display: flex;
    justify-content: space-between;
  }

  .multisigMain2 {
    position: sticky;
    top: 27%;
  }

  .section1M {
    padding: 50px;
    width: 40%;
  }

  .section2M {
    padding: 30px;
    width: 100%;
  }

  .m1 {
    font-size: 36px;
    color: white;
    margin-bottom: 40px;
  }

  .m2 {
    font-size: 36px;
    color: #9198b0;
    margin-bottom: 40px;
  }

  .lines2Main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

  }

  .lines2 {
    height: 1px;
    width: 80%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2-2 {
    height: 1px;
    width: 100%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2Con {
    color: #9198b0;
    width: 100%;
    text-align: center;
    font-size: 32px;
  }

  .lines2Con-2 {
    color: #9198b0;
    text-align: center;
    padding: 25px;
    font-size: 32px;
  }

  .inpuyDiv2 {
    border: #c3cdee 4px solid;
    border-radius: 15px;
    margin-bottom: 7%;
  }

  .inpuyDiv2 input {
    border: none;
    outline: none;
    background: none;
    padding: 20px;
    font-size: 36px;
  }

  .buttonM {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-size: 32px;
  }

  .buttonM:hover {
    background-color: #8b44ac;
  }

  .multisig2ndBox {
    display: flex;
    justify-content: space-between;
    font-size: 32px;
  }

  .tokensT {
    font-size: 36px;
    font-weight: bold;
    color: #9198b0;
    margin-top: 35px;
  }

  .box2M {
    background-color: #1f2846;
    padding: 60px;
    border-radius: 25px;
    margin-bottom: 5%;
    font-size: 32px;
  }

  .multisigH {
    color: #818aa3;
    font-weight: bold;
    font-size: 60px;
  }

  .multisigH2 {
    color: #818aa3;
    margin-bottom: 40px;
    font-size: 32px;
  }
}

@media screen and (min-width: 3840px) {

  .wallet {
    display: flex;
    background-color: #2c3556;
    padding: 32px;
    border-radius: 16px;
  }

  .wallet div {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 45px;
  }

  .icon2 {
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 40px;
  }

  .amountDetails2 {
    background-color: #2c3556;
    padding: 50px;
    border-radius: 20px;
  }

  .smallH {
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 45px;

  }

  .smallBtns {
    width: 380px;
  }

  .filAmount {
    font-size: 45px;
    font-weight: normal;
  }

  .amountDetails {
    background-color: #2c3556;
    padding: 50px;
    width: 1170px;
    border-radius: 20px;
  }

  .amountDetailsMain {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
  }

  .balanceD {
    font-size: 50px;
    font-weight: bold;
  }

  .amountD {
    font-size: 70px;
    font-weight: bold;
    margin-top: 20px;
  }

  .iconsAddressMain {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .iconsAddress {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .copyImg {
    width: 30px;
    height: 30px;
    margin-left: 20px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .copyImg:hover {
    opacity: 1;
  }

  .icon {
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 30%;
  }

  .iconsAddress div {
    color: #8e96ae;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
  }

  .flexBtns {
    display: flex;
    justify-content: space-between;
  }

  .flexBtns div {
    margin: 20px;
  }

  .filAndAmount {
    display: flex;
  }

  .fil {
    color: #8e96ae7e;
    font-size: 45px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .tfDiv {
    display: flex;
    justify-content: space-between;
  }

  .address2 {
    margin-top: 20px;
    margin-left: 50px;
  }

  .tfDiv img {
    width: 65px;
    height: 65px;
  }

  .ApprovedBtn {
    background-color: #266ac4;
    border: none;
    border-radius: 15px;
    padding: 15px;
    margin-left: 20px;
    font-size: 43px;
  }

  #copy-notification {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: rgba(255, 255, 255, 0.281);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    font-size: 50px;
  }

  .copy {
    width: 55px;
    height: 55px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
  }

  .copy:hover {
    opacity: 1;
  }

  #onClick {
    cursor: pointer;
    width: 100%;
    padding: 35px;
    margin-bottom: 35px;
    border-radius: 35px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  #selected2 {
    background-color: #3c435c8f;
    border: 1px solid #8e96ae9d;
    width: 100%;
    padding: 35px;
    margin-bottom: 35px;
    border-radius: 35px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .input-container-Main {
    background-color: #1f28469f;
    width: 50%;
    padding: 60px;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .input-container div {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    font-size: 50px;
  }

  .inP1 {
    font-size: 65px;
    font-weight: bold;
  }

  .inP2 {
    color: #8e96ae;
    margin-top: -10px;
    margin-bottom: 30px;
    font-size: 50px;
  }

  .input-container input {
    background-color: #1f2949;
    border: none;
    outline: none;
    width: 1150px;
    padding: 30px;
    border-radius: 25px;
    color: #8e96ae;
    font-size: 50px;
  }


  .filAndAmount {
    background-color: #1f2949;
    width: 1150px;
    border-radius: 25px;
  }

  .filB {
    margin-right: 35px;
    font-size: 50px;
  }

  li {
    color: #8e96ae;
    font-size: 50px;
  }

  li::marker {
    color: #98d5e9;
  }

  a {
    text-decoration: none;
    color: #8e96ae;
  }

  a:hover {
    color: #b7c1df;
  }

  .proMain {
    display: flex;
    justify-content: space-between;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    /* Adjust width as needed */
    margin: 20px auto;
    /* Add some margin for better placement */
  }

  th,
  td {
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid #8e96ae3f;
    font-size: 45px;
    color: #b2bad3;
    cursor: pointer;
  }

  th {
    background-color: #8e96ae33;
    color: #c2cdee;
    font-weight: 500;
    font-size: 45px;
  }

  .selected {
    background-color: #c2cdee38;
  }

  .address {
    font-size: 45px;
    font-weight: 200;
    color: #bec8d3;
    margin-top: 20px;
  }

  .action {
    text-align: center;
    /* Center text in the "ACTION" column */
  }

  .signerSection {
    display: flex;
    justify-content: space-between;
  }

  .signerSection div {
    font-size: 65px;
    color: #8e96ae;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .signerSection button {
    font-size: 50px;
    background: none;
    padding: 40px;
    border-radius: 100px;
    color: #8e96ae;
    border: 1px solid #8e96ae;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .signerSection button:hover {
    background-color: #8e96ae25;
  }

  .logoDiv img:hover {
    cursor: pointer;
  }

  .footer-nav li:hover {
    color: #66ccff;
    cursor: pointer;
  }

  .multisigMain-1 {
    background-color: rgba(7, 19, 27, 0.842);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }

  .bone {
    width: 14%;
    bottom: 4%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 2%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
  }

  .paw {
    width: 7%;
    bottom: 5%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 90%;
    rotate: 30deg;
  }

  .footer {
    background-color: #222;
    color: #fff;
    padding: 160px 0;
    text-align: center;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.6634825805322129) 100%), url('./assets/footer.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top: 15px solid rgba(113, 179, 255, 0.185);
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
  }

  .footer-logo {
    font-size: 70px;
    font-weight: bold;
    margin-right: auto;
  }

  .footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: auto;
  }

  .footer-nav li {
    display: inline-block;
    margin-right: 70px;
    font-size: 45px;
  }

  .footer-nav li:last-child {
    margin-right: 0;
  }

  .footer-nav li {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer-nav li a:hover {
    color: #66ccff;
  }

  .footer-text {
    margin-top: 20px;
    font-size: 50px;
    font-family: "Inconsolata", monospace;
    color: #8e96ae;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 90px;

    background-color: rgba(172, 67, 67, 0);
    width: 300px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }


  .check {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 60px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 67px;
    margin-bottom: 10px;
    color: #2ce7ba;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
  }

  .success-message p {
    font-size: 34px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 67px;
    color: #41e0fc;
    text-align: center;
    font-family: "Inconsolata", monospace;
    font-weight: bold;

  }


  /* HTML: <div class="loader"></div> */
  .loader {
    height: 30px;
    aspect-ratio: 3;
    --c: no-repeat linear-gradient(#b141fc 0 0);
    background:
      var(--c) calc(0*100%/5) 50%,
      var(--c) calc(1*100%/5) 50%,
      var(--c) calc(2*100%/5) 50%,
      var(--c) calc(3*100%/5) 50%,
      var(--c) calc(4*100%/5) 50%,
      var(--c) calc(5*100%/5) 50%;
    background-size: calc(100%/6 + 1px) 50%;
    animation: l19 1s infinite cubic-bezier(0.5, 170, 0.5, -170);
  }

  @keyframes l19 {
    14.28% {
      background-position: calc(0*100%/5) 51%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    28.57% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 51%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    42.85% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 51%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    57.14% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 51%, calc(4*100%/5) 50%, calc(5*100%/5) 50%
    }

    71.42% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 51%, calc(5*100%/5) 50%
    }

    85.71% {
      background-position: calc(0*100%/5) 50%, calc(1*100%/5) 50%, calc(2*100%/5) 50%, calc(3*100%/5) 50%, calc(4*100%/5) 50%, calc(5*100%/5) 51%
    }
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 12px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #535353;
  }

  .max {
    background-color: #9696965e;
    border: none;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px;
    margin-right: 25px;
    font-size: 45px;
    padding: 10px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px rgba(255, 255, 255, 0.151) solid;
  }

  .max:hover {
    background-color: rgb(110, 110, 110);
  }

  .web3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.20689994747899154) 0%, rgba(0, 0, 0, 0.4057794992997199) 50%, rgba(0, 0, 0, 0.8735666141456583) 100%), url('./assets/t10.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    filter: brightness(1);
  }


  .light2 {
    height: 100vh;
  }

  .miniBarMain {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #02071a00;
    z-index: 100000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #fontSize {
    font-size: 45px;
    cursor: pointer;
    z-index: 10000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    color: #8d98b0;
    font-family: "Inter", sans-serif;
  }

  #fontSize:hover {
    color: rgb(209, 209, 209);
  }

  .activeNav {
    color: #cad9fa;
  }

  .logoDiv {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv img {
    width: 230px;
    margin-right: 10px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 55px;
    margin-right: 55px;
  }

  .connectBtn {
    color: #daccff;
    padding: 35px;
    font-size: 45px;
    width: 500px;
    background-color: #7722ff69;
    border: none;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50px;
    font-weight: 500;
    border: 1px solid #7722fffd;

  }

  .connectBtn:hover {
    background-color: #6226ff;
  }

  .introductionMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4%;
    text-align: center;
  }

  .in1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 80px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    background: linear-gradient(to left, #2dd7b9, #2dd7b9, #3ab4cc, #3a0cb8, #2e0699);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 3px;
  }

  .in2 {
    font-size: 160px;
    color: rgb(255, 255, 255);
    margin-top: 2.5%;
    margin-bottom: 3%;
    font-family: "Tilt Warp", sans-serif;
  }

  .amount {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
  }

  .number {
    color: #2ce7ba;
    font-size: 90px;
    font-weight: bold;
  }

  .nameNum {
    color: #8e96ae;
    font-weight: 600;
    font-size: 42px;
    margin-top: 8px;
  }

  .connectBtn2 {
    box-shadow: 0px 10px 45px rgba(5, 171, 221, 0.096);
    font-style: normal;
    font-size: 16px;
    margin-top: 5%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: #daccff;
    font-weight: bold;
    padding: 70px;
    font-size: 58px;
    width: 1000px;
    background-color: #3713921f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 7px solid rgb(150, 222, 255);
    font-family: "Inconsolata", monospace;
    border-radius: 30px;
    /* Add this line for border-radius */
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.288);
    /* Add this line for glow effect on hover */

  }

  .connectBtn2:hover {
    box-shadow: 0px 0px 20px 5px rgba(150, 222, 255, 0.377);
    /* Add this line for glow effect on hover */
    color: #cbb6fc;
  }

  .shib {
    width: 30%;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
  }

  .faqI {
    width: 5%;
    bottom: 3%;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 90%;
    animation: floatAnimation 3s infinite;
    cursor: pointer;
    transition: transform .2s;
  }

  .faqI:hover {
    transform: scale(1.1);
  }

  @keyframes floatAnimation {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5178243172268908) 0%, rgba(0, 0, 0, 0.7811296393557423) 50%, rgba(0, 0, 0, 0.8959755777310925) 100%), url('./assets/brick.png') center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .header {
    color: white;
    text-align: center;
    font-size: 40px;
    margin-bottom: 2%;
    font-family: "Tilt Warp", sans-serif;
  }

  .aboutT {
    font-size: 40px;
    color: white;
    font-family: "Tilt Warp", sans-serif;
  }

  .icons {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons img {
    width: 20%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons img:hover {
    opacity: 1;
  }

  .footerMain {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(22, 22, 22);
  }

  .footer1 {
    color: rgb(88, 88, 88);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-top: 5px;
  }

  .iconsF {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .iconsF img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.5s ease;
    cursor: pointer;
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  .iconsF img:hover {
    transform: scale(1.2);
  }

  summary {
    font-size: 20px;
    background: #af3d3300;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.932);
    border: 1px rgba(255, 255, 255, 0.322) solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    text-shadow: 0 0 10px #ffffff94;
    padding-bottom: 10px;
    text-align: left;
    font-size: 18px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  #faq {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .faqbg {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(17, 15, 35);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  /* STAKING CSS */

  .stake {
    min-height: 100vh;
    height: max-content;
  }

  .stakeMain {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 2%;
  }

  .stakeMain img {
    width: 150px;
    height: 150px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .box1 {
    background-color: #00151dad;
    display: flex;
    justify-content: space-between;
    padding: 65px;
    border-radius: 40px;
    width: 25%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 0 20px 0 rgba(42, 253, 243, 0.5);
    border: 1px solid rgba(42, 253, 242, 0.842);
  }

  .stakeH {
    color: white;
    font-size: 55px;
    font-weight: 600;
    line-height: 1.5;
  }

  .stakeP {
    color: #8a90aa;
    font-weight: bold;
    line-height: 1.5;
    font-family: "Inconsolata", monospace;
    font-size: 55px;
  }

  #balance2 {
    color: #8a90aa;
    font-weight: 500;
    font-size: 45px;
  }

  .stakeH2 {
    color: white;
    line-height: 1.5;
    font-size: 45px;
  }

  .stakeBox {
    background-color: rgba(7, 19, 27, 0.842);
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 80px;
    border-radius: 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .inputSection {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 30px;
    margin-top: 65px;
    margin-bottom: 65px;
    font-size: 45px;
  }

  .inputSection input {
    font-size: 45px;
    padding: 45px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .note {
    margin-bottom: 65px;
    color: #6b6b6b;
    font-size: 45px;
  }

  .hl2 {
    color: #246474;
  }

  .rewards {
    text-align: center;
    margin-bottom: 10%;
    margin-top: 10%;
    font-size: 55px;
    color: #2ce7ba;
  }

  .inputError {
    color: rgb(255, 79, 79);
  }

  .inputSection div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 45px;
  }


  .inputSection2 {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    border-radius: 30px;
    margin-top: 85px;
    font-size: 45px;
  }

  .inputSection2 input {
    font-size: 45px;
    padding: 45px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }

  .inputSection2 div {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 30px;
  }

  .stakeBox2 {
    margin-top: 5%;
  }

  .deposit {
    margin-left: auto;
    margin-right: auto;
    font-size: 45px;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 45px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .deposit:hover {
    background-color: #8b44ac;
  }

  .depositMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .stakeBox2Flex {
    display: flex;
    justify-content: space-between;
  }

  .stakeNav {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    margin-top: 5%;
  }

  .stakeNav div {
    background-color: #003d6656;
    padding: 30px;
    border-radius: 30px;
    width: 400px;
    text-align: center;
    cursor: pointer;
    transition: 0.5 ease-in-out;
    font-size: 45px;
  }

  .stakeNav div:hover {
    background-color: #0064a756;
  }

  #onStake {
    background-color: #0585db9a;
    border: 1px solid #8a90aa;
  }

  .balance {
    font-size: 24px;
    font-weight: bold;
    font-family: "Inconsolata", monospace;
    display: flex;
    justify-content: space-between;
  }

  #balance {
    font-size: 60px;
    color: #4ddbff;
  }

  .line {
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    margin-top: 35px;
    background-color: rgba(159, 195, 248, 0.288);
  }

  .multisigMain {
    display: flex;
    justify-content: space-between;
    padding-top: 3%;
  }

  .multisigMain2 {
    position: sticky;
    top: 27%;
  }

  .section1M {
    padding: 100px;
    width: 40%;
  }

  .section2M {
    padding: 30px;
    width: 100%;
  }

  .m1 {
    font-size: 50px;
    color: white;
    margin-bottom: 60px;
  }

  .m2 {
    font-size: 50px;
    color: #9198b0;
    margin-bottom: 60px;
  }

  .lines2Main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

  }

  .lines2 {
    height: 1px;
    width: 80%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2-2 {
    height: 1px;
    width: 100%;
    background-color: #9198b0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .lines2Con {
    color: #9198b0;
    width: 100%;
    text-align: center;
    font-size: 45px;
  }

  .lines2Con-2 {
    color: #9198b0;
    text-align: center;
    padding: 35px;
    font-size: 45px;
  }

  .inpuyDiv2 {
    border: #c3cdee 7px solid;
    border-radius: 25px;
    margin-bottom: 7%;
  }

  .inpuyDiv2 input {
    border: none;
    outline: none;
    background: none;
    padding: 40px;
    font-size: 50px;
  }

  .buttonM {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #662f80;
    width: 100%;
    align-items: center;
    padding: 40px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-size: 45px;
  }

  .buttonM:hover {
    background-color: #8b44ac;
  }

  .multisig2ndBox {
    display: flex;
    justify-content: space-between;
    font-size: 45px;
  }

  .tokensT {
    font-size: 36px;
    font-weight: bold;
    color: #9198b0;
    margin-top: 50px;
  }

  .box2M {
    background-color: #1f2846;
    padding: 100px;
    border-radius: 30px;
    margin-bottom: 5%;
    font-size: 45px;
  }

  .multisigH {
    color: #818aa3;
    font-weight: bold;
    font-size: 90px;
  }

  .multisigH2 {
    color: #818aa3;
    margin-bottom: 55px;
    font-size: 45px;
  }
}

.filAndCon {
  display: flex;
  justify-content: space-between;
}